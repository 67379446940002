@import 'colors.scss';
@import 'fonts.scss';

.job-item-detail-container {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'flex-start';
  align-items: 'flex-start';
  min-width: auto;
  min-height: 471;
  flex-grow: 1;
  padding: 30px;
  margin: 20px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  .job-item-img {
    position: relative;
    height: 57px;
    img {
      min-width: auto !important;
      width: 57px !important;
      margin: 0 !important;
    }
  }
  .job-title {
    font-size: 21px;
    color: $primary;
    font-family: $font_lato_bold;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0px;
  }
  .job-ref-container {
    height: auto;
    .job-ref {
      width: 100%;
      border: 1px solid $primary;
      border-radius: 5px;
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 20px;
      .job-ref-text {
        font-size: 14px;
        color: $primary;
        font-family: $font_lato_regular;
        text-align: left;
        margin: 6px;
      }
    }
  }
  .job-date-container {
    height: 30px;
    margin-top: 15px;
    .job-date {
      width: 100%;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
  
      @media (max-width: 320px) {
        display: flex;
        flex-direction: column;
      }
      
      .job-date-text {
        font-size: 14px;
        color: $primary;
        font-family: $font_lato_regular;
        text-align: left;
        margin-right: 2px;
      }
    }
  }
  .block-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    .text-description {
      color: $descriptionGrey;
      font-family: $font_lato_regular;
      margin-left: 20px;
    }
  }

  .reverse-direction {
    display: flex;
    flex-direction: row-reverse;

    .job-btn {
      width: 100%;
      padding-top: 30px;
      margin-top: auto;
      button {
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        z-index: 1;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        max-width: 100%;
        height: 40px;
        background-color: $yellow;
        color: $primary;
        font-family: $font_lato;
        font-size: 14px;
        span.bg {
          background: $primary;
          width: 0;
          left: 0;
          height: 100%;
          position: absolute;
          opacity: 0;
          z-index: -1;
          transition: all 0.5s ease-in-out;
          border-radius: 5px;
          top: 0;
        }
        &:hover {
          color: $white;
          span.bg {
            transition: all 0.5s ease-in-out;
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}

hr.line {
  width: 58px;
  height: 2px;
  background-color: $primary;
  margin-top: 30px;
  margin-bottom: 30px;
}

.loader-candidature {
  margin-left: 10px;
  color: $white;
  height: 20px !important;
  width: 20px !important;
}

.job-title-options {
  font-size: 18px;
  color: $primary;
  font-family: $font_lato_regular;
  text-align: left;
  width: 120px;
}
.job-title-category {
  font-size: 18px;
  color: $primary;
  font-family: $font_lato_regular;
  text-align: left;
  margin-left: 10px;
}

.job-content-options {
  margin: 20px;
}

.margin-10 {
  margin-top: 10px;
}

.margin-20 {
  margin-top: 20px;
}

.left-position-btn {
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  .job-btn {
    width: 100%;
    padding-top: 30px;
    margin-top: auto;
    button {
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      z-index: 1;
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      width: 135px;
      max-width: 100%;
      height: 40px;
      background-color: $yellow;
      color: $primary;
      font-family: $font_lato;
      font-size: 14px;
      span.bg {
        background: $primary;
        width: 0;
        left: 0;
        height: 100%;
        position: absolute;
        opacity: 0;
        z-index: -1;
        transition: all 0.5s ease-in-out;
        border-radius: 5px;
        top: 0;
      }
      &:hover {
        color: $white;
        span.bg {
          transition: all 0.5s ease-in-out;
          width: 100%;
          opacity: 1;
        }
      }
    }
  }
}

.container-statique{
  display: flex;
  flex-direction: column;
  margin: 20px 150px;
  @media (max-width: 900px) and (min-width: 600px) {
    margin: 20px 100px;
  }
  @media (max-width: 600px) and (min-width: 400px) {
    margin: 20px 50px;
  }
  @media only screen and (max-width: 400px) {
    margin: 20px 30px;
  }
 
  .title-statique {
    font-size: 21px;
    color: $primary;
    font-family: $font_lato_bold;
    text-align: left !important;
    margin: 8px 0px;
  }
  .text-statique {
    color: $descriptionGrey;
    font-family: $font_lato_regular;
    margin-left: 20px;
    text-align: justify;
    margin: 8px 0px;
  }
}

