@import 'main.scss';

@keyframes color-me-in {
  0% {
    background-color: $transparentWhite;
  }
  100% {
    background-color: $white;
  }
}

@keyframes FillInYellow {
  from {
    fill: $white;
  }
  to {
    fill: $yellow;
  }
}

@keyframes showSearchAnimation {
  from {
    width: 0;
    opacity: 0;
  }
  90%,
  to {
    width: 100%;
    opacity: 1;
  }
}

.menu-container {
  position: absolute;
  top: 0;
  z-index: 9;
  width: 100%;
  color: $white;
  .menu-logo {
    z-index: 1;
    margin-right: 50px;
    a {
      display: flex;
      svg {
        margin-top: -2px;
      }
    }
  }
  &.dark-menu {
    .header-logo svg:hover {
      .logo-yellow {
        animation: FillInYellow 0.8s forwards ease-in-out;
      }
    }
  }
  .contact-btn-container {
    z-index: 1;
  }
  .contact-btn {
    text-transform: none;
    font-family: $font_lato_bold;
    @include sizeXSmall($xSmallFontSize);
    text-decoration: none;
    cursor: pointer;
    padding: 0 18px;
  }
  .menu-content {
    padding: 20px 150px;
  }
  .menu-content-responsive {
    padding: 20px 10px;
    flex-wrap: nowrap;
    padding-right: 58px;
    svg {
      max-width: 100%;
    }
    .menu-responsive-logo {
      margin: auto;
    }
  }
  button {
    color: $white;
  }
  &.light-menu {
    background-color: $white;
    color: $primary;
    animation: color-me-in 1s 1;
    box-shadow: 0 4px 2px -2px gray;
    position: fixed;

    button {
      color: $primary;
    }
    svg {
      .logo-blue {
        fill: $primary;
        path {
          fill: $primary;
        }
      }
      .logo-yellow {
        fill: $yellow;
      }
    }
  }
  .currency-selector {
    display: flex;
    align-items: center;
    button {
      font-size: 17px;
      font-family: $font_lato_regular;
      padding-top: 0;
      padding-bottom: 0;
    }

    .currency-code {
      margin-right: 5px;
    }
    .currency-dropdown-name,
    .currency-dropdown-code {
      color: $primary;
      font-size: 13px;
      font-family: $font_lato_bold;
      padding: 5px;
    }
    .currency-dropdown-code {
      text-transform: uppercase;
    }
  }
  .language-selector {
    @include sizeSmall($xSmallFontSize);
    .language-country-code {
      text-transform: uppercase;
    }
    .language-language-code {
      text-transform: capitalize;
      margin-right: 5px;
    }
    button.language-btn {
      @include sizeSmall($xSmallFontSize);
      font-family: $font_lato_regular;
    }
    svg.world-icon {
      margin-right: 5px;
    }
  }

  .MuiPaper-root {
    position: relative;
  }

  .seach-open {
    position: relative;
    .menu-list-item {
      opacity: 0;
      transition: opacity 0.5s;
      z-index: -1;
    }
    .search-container {
      animation: showSearchAnimation 0.5s forwards;
      opacity: 0;
      display: flex;
      opacity: 1;
      transition: opacity 0.5s 0.5s;
      transition-delay: 0.5s;
    }
    .MuiInput-underline:after {
      border-color: $yellow;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-color: $white;
    }
    .MuiInputBase-root {
      color: $white;
    }
    svg {
      fill: $white;
    }
  }
  .menu-list-item {
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 1;
  }
  .search-container {
    display: none;
    .search-input {
      min-width: 60%;
    }
    .search-autocomplete {
      display: flex;
      justify-content: center;
    }
    .MuiInput-underline:before {
      border-color: $white;
    }
    label,
    .MuiFormLabel-root.Mui-focused {
      color: $white;
    }
  }
}
.MuiAutocomplete-popper * {
  color: $primary;
}
.language-dropdown {
  z-index: 9;
  .list-title {
    font-family: $font_lato_bold;
    font-size: 13px;
    padding: 20px 20px 0px 20px;
    color: $primary;
  }
  hr.title-underline {
    width: 70%;
    margin-left: 20px;
    background-color: $primary;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  .countries-list {
    box-shadow: 10px 0 7px -5px $transparentShadow;
    padding-bottom: 20px;
    width: 50%;
    min-width: 190px;
    color: $primary;
    ul {
      padding-top: 0;
    }
    .country-alpha {
      font-size: 14px;
      font-family: $font_lato_bold;
      text-transform: uppercase;
      padding: 4px 10px 8px 16px;
    }
    li {
      font-family: $font_lato_regular;
    }
  }
}
.flag_icon {
  position: relative;
  width: 26px;
  height: 17px;
  border-radius: 5px;
  margin-right: 7px;
  * {
    border-radius: 5px !important;
  }
  img {
    border: 1px solid $hoverColor !important;
  }
}
.language-menu-abv {
  text-transform: capitalize;
}

.languages-list {
  min-width: 190px;
  padding-bottom: 5px;
  text-transform: none;
  color: $primary;
  width: 50%;
  li {
    font-size: 14px;
    text-transform: none;
    font-family: $font_lato_regular;
  }
  ul {
    padding-left: 7px;
  }
}
.currencies-dropdown {
  min-width: 220px;
}
.side-bar .MuiDrawer-paper {
  width: 80%;
}

.popper-style.search-style:after {
  right: 40%;
}
.search-dropdown.menu-dropdown.full-width {
  left: 5px !important;
}
.search-engine-container {
  padding-top: 10px;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root:hover:before {
    border-bottom-color: $primary !important;
  }
  .MuiInput-underline:before {
    border-bottom-color: $primary;
  }
  .MuiInput-underline:after {
    border-color: $yellow;
  }
  .MuiFormLabel-root {
    color: $placeHolderColor;
  }
}
.search-dropdown .close-btn {
  z-index: 1;
}

@media only screen and (max-width: 1500px) and (min-width: 1300px) {
  .menu-container .menu-content,
  .full-drop-down-container {
    padding: 20px 100px !important;
  }
}

@media only screen and (max-width: 1300px) {
  .menu-container .menu-content {
    padding: 20px 0.5% !important;
  }
  .full-drop-down-container {
    padding: 20px 40px !important;
  }
  .menu-logo {
    margin-right: 10px !important;
  }
  .menu-content .MuiIconButton-root {
    padding: 5px;
  }
  .menu-container .contact-btn {
    padding: 0 10px;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
