$smallFontSize: 16px;
$xSmallFontSize: 14px;
$mediumFontSize: 20px;

@mixin sizeExtraLarge($size) {
  font-size: $size;
  @media only screen and (max-width: 900px) {
    font-size: calc(#{$size} - 50px);
  }
}

@mixin sizeLarge($size) {
  font-size: $size;
  @media only screen and (max-width: 900px) {
    font-size: calc(#{$size} - 20px);
  }
}

@mixin sizeMedium($size) {
  font-size: $size;
  @media only screen and (max-width: 900px) {
    font-size: calc(#{$size} - 10px);
  }
}

@mixin sizeSmall($size) {
  font-size: $size;
  @media (max-width: 1400px) and (min-width: 1300px) {
    font-size: calc(#{$size} - 2px);
  }
  @media (max-width: 1300px) {
    font-size: calc(#{$size} - 3px);
  }
}

@mixin sizeXSmall($size) {
  font-size: $size;
  @media (max-width: 1300px) {
    font-size: calc(#{$size} - 2px);
  }
}
