@import 'main.scss';

.site-map-section-container {
  padding: 10px 100px;

  @media (max-width: 1000px) {
    padding: 10px 50px;
  }
  @media (max-width: 500px) {
    padding: 10px 20px;
  }

  .site-map-section-item {
    padding: 10px 20px;
    .site-map-section-item-title {
      font-family: $font_lato_bold;
      text-transform: capitalize;
      font-size: 17px;
      display: flex;
      flex-direction: column;
      .title-underline {
        background-color: $yellow;
        margin-bottom: 10px;
        width: 80%;
        height: 2px;
      }
    }
  }
  a {
    color: $primary;
    text-decoration: none;
    padding: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
  .single-link {
    font-family: $font_lato_bold;
    font-size: 17px;
  }
}
