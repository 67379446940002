@import 'colors.scss';
@import 'fonts.scss';
@import 'constants.scss';

@font-face {
  font-family: $font_lato;
  src: url($assets_root + $font_lato_path) format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font_lato_regular;
  src: url($assets_root + $font_lato_regular_path) format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: $font_lato_bold;
  src: url($assets_root + $font_lato_bold_path) format('truetype');
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: $font_catull_regular;
  src: url($assets_root + $font_catull_regular_path) format('truetype');
  font-style: normal;
  font-display: swap;
}
