@import 'main.scss';

.image-text-bloc {
  .main-bloc-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 400px;
    min-width: 100% !important;

    .bloc-container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 1000px;
      .image-container {
        position: relative !important;
        border-radius: 10px;

        img {
          border-radius: 10px;
        }
      }
      .text-container {
        box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);
        border-radius: 10px;
        .text-shadow {
          width: 100%;
          height: 100%;
          box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);
          border-radius: 10px;
          overflow-y: scroll;
          .container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            .title-bloc {
              margin: 15px;
              color: #004899;
              font-size: 20px;
              font-weight: bold;
              .title {
                color: #004899;
                font-size: 21px;
                line-height: 25px;
                font-family: 'Lato, Regular';
                margin: 0px;
                text-align: center;
              }
            }
            .paragraph-bloc {
              margin: 20px;

              p {
                color: #6f6f6f;
                font-size: 16px;
                line-height: 24px;
                font-family: 'Lato, Regular';
                margin: 0px;
                text-align: center;
              }
            }
          }
        }
        .text-shadow::-webkit-scrollbar {
          width: 5px !important;
        }
        .text-shadow::-webkit-scrollbar-thumb {
          background-color: #f6fafd;
          border: 5px solid #a7a7a8;
          border-radius: 10rem;
        }
        .text-shadow::-webkit-scrollbar-track {
          position: absolute;
          right: -3rem;
          top: -50rem;
          background: transparent;
        }
      }
    }
  }
}

.image-text-bloc-mobile {
  padding: 50px 20px;

  .main-bloc-mobile-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 300px;
  
    .image-mobile-container {
      position: relative;
      border-radius: 10px;
      height: 250px;

      img {
        border-radius: 10px;
      }
    }
    .text-mobile-container {
      width: 100%;
      height: 100%;
      box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);
      border-radius: 10px;
      height: 250px;
      overflow: overlay;
      .text-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title-bloc {
          margin: 15px;
          color: #004899;
          font-size: 20px;
          font-weight: bold;
          .title {
            color: #004899;
            font-size: 21px;
            line-height: 25px;
            font-family: 'Lato, Regular';
            margin: 0px;
          }
        }
        .paragraph-bloc {
          padding: 20px;
          p {
            color: #6f6f6f;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Lato, Regular';
            margin: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 1300px) and (min-width: 600px) {
  .image-text-bloc {
    padding: 10px 10px;
  }
}

@media (max-width: 600px) {
  .image-text-bloc-mobile {
    padding: 10px 10px;
  }
}

@media (max-width: 1300px) and (min-width: 950px) {
  .bloc-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 900px !important;
  }
}
@media (max-width: 950px) and (min-width: 600px) {
  .bloc-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 550px !important;
  }
}


/*** separate bloc style **/


.img-txt-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (min-width: 1600px)  {
    margin: 0px 60px;
  }
  @media only screen and (min-width: 1700px)  {
    margin: 0px 100px;
  }
  .bloc-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 100%;
    .img-txt-bloc {
      display: 'flex';
      flex-direction: 'row';
      justify-content: 'flex-start';
      align-items: 'flex-start';
      min-width: 300px;
      min-height: 200px;
      flex-grow: 1;
      margin: 20px !important;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      @media only screen and (min-width: 1600px)  {
        min-height: 300px;
      }
      .media-info-img {
        height: 100%;
        position: relative;
        box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      padding: 20px;
      


      .title-container {
        height: 50px;
        .title {
          color: $primary;
          font-size: 28px;
          font-family: $font_lato_bold;
        }
      }

      .description-container {
        height: 120px;
        overflow-y: scroll;

        @media only screen and (min-width: 1600px)  {
           height: 160px;
        }
        p {
          color: #6f6f6f;
          font-size: 16px;
          line-height: 24px;
          font-family: 'Lato, Regular';
          margin: 0px;
          text-align: left;
        }
      }
  
      .button-container{
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        button {
          height:40px;
          background: $primary;
          color: $white;
          font-family: $font_lato_bold;
          padding: 10px 25px;
          min-width: 170px;
          margin-top: 5px;
          &:hover {
            background: $yellow;
            color: $primary;
          }
        }
      }

      
      .description-container::-webkit-scrollbar {
        width: 5px !important;
      }
      .description-container::-webkit-scrollbar-thumb {
        background-color: #f6fafd;
        border: 5px solid #a7a7a8;
        border-radius: 10rem;
      }
      .description-container::-webkit-scrollbar-track {
        position: absolute;
        right: -3rem;
        top: -50rem;
        background: transparent;
      }
    }
   
  }
}

.image-text-bloc-corporate-mobile {
  padding: 50px 20px;
 
  .main-bloc-mobile-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    .image-mobile-container {
      position: relative;
      height: 400px;
      min-width: 300px;
      box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);

      @media only screen and (max-width: 700px)  {
        height: 300px;
      }
    }
    .text-mobile-container {
      height: 100%;
      box-shadow: -1px 1px 19px 4px rgb(0 0 0 / 10%);
      margin-top: 15px;
      height: auto;
      min-width: 290px;
      overflow: overlay;
      .text-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .title-bloc {
          margin: 15px;
          color: #004899;
          font-size: 20px;
          font-weight: bold;
          .title {
            color: #004899;
            font-size: 21px;
            line-height: 25px;
            font-family: 'Lato, Regular';
            margin: 0px;
          }
        }
        .paragraph-bloc {
          padding: 20px;
          min-height: 100px;
          p {
            color: #6f6f6f;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Lato, Regular';
            margin: 0px;
          }
        }
        .button-container{
          height: 50px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          button {
            height:40px;
            background: $primary;
            color: $white;
            font-family: $font_lato_bold;
            padding: 10px 25px;
            min-width: 100px;
            &:hover {
              background: $yellow;
              color: $primary;
            }
          }
        }
  
      }
    }
  }
}

@media (max-width: 650px)  {
  .img-txt-container {
    .img-txt-bloc {
      display: 'flex';
      flex-direction: 'row';
      justify-content: 'flex-start';
      align-items: 'flex-start';
      min-width: 180px;
      min-height: 350px;
      flex-grow: 1;
      margin: 20px !important;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      .media-info-img {
        height: 100%;
        position: relative;
      }
    }
  }

}