@import 'main.scss';

.gallery-slider {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  &.gallery-slider-mobile {
    height: unset;
    margin-bottom: 0px;
  }

  .mobile-slider {
    margin-left: 0 !important;
    padding-right: 15% !important;
    overflow: visible;
    &.gallery-slider-one {
      padding-right: 0 !important;
    }
    .embla {
      width: 100%;
      overflow: visible;
      .embla__viewport {
        overflow: visible;
      }
      .gallery-item {
        padding: 10px;
      }
      .gallery-slider-item {
        box-shadow: 0px 3px 6px $transparentShadow;
        width: 100%;
        position: relative;
        height: 230px;
        border-radius: 10px;
        img {
          border-radius: 10px;
        }
      }
    }
  }
  .embla__dots {
    .dot-button {
      background: $primary;
      width: 5px;
      height: 5px;
      opacity: 0.5;
      padding: 0;
      margin: 2.5px;
      &.selected {
        width: 10px;
        height: 10px;
        opacity: 1;
      }
    }
  }
  .slick-next-arrow,
  .slick-prev-arrow {
    position: absolute;
    bottom: -70px;
    height: 35px;
    width: 35px;
    top: unset;
    padding: 0;
    svg {
      font-size: 35px;
      color: $primary;
    }
    &.slick-disabled {
      opacity: 0.25;
    }
  }
  .slick-next-arrow {
    right: calc(50% - 35px);
  }
  .slick-prev-arrow {
    left: calc(50% - 35px);
  }
  .slick-slider {
    min-width: 100%;
    text-align: center;
    .slick-track {
      display: flex;
      align-items: center;
      min-height: 355px;
    }
    .slick-center {
      z-index: 1 !important;
      .gallery-slider-item {
        height: 350px;
        border-radius: 10px;
        width: 130% !important;
        margin-left: -15%;
        box-shadow: 0px 3px 6px $transparentShadow;
      }
    }
    .slick-slide {
      z-index: -1;
    }
    .gallery-slider-item {
      height: 250px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      transition: all 0.1s linear;
      position: relative;
      &:focus-visible {
        outline: none;
      }
    }
    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      &:focus-visible {
        outline: none;
      }
    }
    @media (max-width: 900px) {
      .slick-center {
        .gallery-slider-item {
          width: 100% !important;
          margin-left: 0 !important;
        }
      }
    }
  }
  &.one-slide-slider {
    max-width: 545px;
    .slick-slider {
      .slick-center {
        .gallery-slider-item {
          width: 100% !important;
          margin-left: 0% !important;
        }
      }
      .slick-slide {
        z-index: 1;
      }
    }
  }
}

.paginated-gallery-container {
  &.paginated-gallery-container-loading {
    min-height: 370px;
  }
  .paginated-gallery-content {
    padding-top: 40px;
    .paginated-gallery-list {
      .paginated-gallery-item-0-3,
      .paginated-gallery-item-1-3,
      .paginated-gallery-item-0-1,
      .paginated-gallery-item-0-2 {
        padding-right: 20px;
      }
      .paginated-gallery-item-1-3,
      .paginated-gallery-item-2-3,
      .paginated-gallery-item-0-1,
      .paginated-gallery-item-1-2 {
        padding-left: 20px;
      }
      .paginated-gallery-item-detail {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    &.paginated-gallery-content-mobile {
      .paginated-gallery-item-detail {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    .paginated-gallery-item {
      .paginated-gallery-item-file {
        position: relative;
        height: 225px;
        border-radius: 10px;
        box-shadow: 0px 3px 6px $transparentShadow;
        cursor: zoom-in;
        img {
          border-radius: 10px;
        }
        &.video-file {
          cursor: initial;
          .video-container {
            height: 100%;
            position: relative;
            .video-pverlay {
              border-radius: 10px;
              position: absolute;
              z-index: 1;
              background: $primaryWithOpacity;
              top: 0%;
              left: 0%;
              height: 100%;
              width: 100%;
              button {
                padding: 5px;
              }
              svg {
                font-size: 50px;
                color: $primary;
              }
            }
          }
        }
      }
      .paginated-gallery-item-title {
        color: $primary;
        font-size: 24px;
        font-family: $font_lato_bold;
        padding-top: 20px;
        a {
          word-break: break-word;
          color: $primary;
          text-decoration: none;
        }
      }
      .paginated-gallery-item-description {
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        text-align: left;
        color: $descriptionGrey;
        font-size: 16px;
        padding-top: 20px;
      }
    }
  }
  .second-gallery-slider-mobile {
    overflow: visible;
    .embla {
      width: 100%;
      overflow: visible;
      .embla__viewport {
        overflow: visible;
      }
      .gallery-item {
        padding: 10px;
      }
      .gallery-slider-item {
        box-shadow: 0px 3px 6px $transparentShadow;
        width: 100%;
        position: relative;
        height: 205px;
        border-radius: 10px;
        img {
          border-radius: 10px;
        }
      }
    }
  }
}

.media-item-modal {
  height: 100%;
  width: 100%;
  .close-btn {
    position: absolute;
    z-index: 1;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    right: 10px;
    top: 10px;
    background: $transparentPrimary;
    &:hover {
      background: $transparentPrimary;
    }
  }
  .media-item-modal-content,
  .MuiDialog-paperScrollPaper {
    overflow: hidden;
  }
  .media-item-modal-file {
    max-height: calc(100% - 20px);
    overflow: hidden;
    max-width: 100%;
    span,
    div,
    img {
      position: relative !important;
      height: auto !important;
      width: auto !important;
      max-height: 100%;
      max-width: 100%;
      min-width: unset !important;
      min-height: unset !important;
    }
  }
  .media-item-modal-photos {
    max-height: calc(100% - 20px);
    overflow: hidden;
    max-width: 100%;
    display: block;
    
    span,
    div,
    img {
     position: relative !important;
     height: auto !important;
     width: auto !important;
     max-height: 100%;
     max-width: 100%;
     min-width: unset !important;
     min-height: unset !important;

     @media (max-width: 1200px) and (min-width: 1000px) {
      width: 980px !important;
     }

    }
  }
  .media-item-modal-photos-mobile {
    max-height: calc(100% - 20px);
    overflow: hidden;
    max-width: 100%;
    span,
    div,
    img {
     position: relative !important;
     height: auto !important;
     width: 600px !important;
     max-height: 100%;
     max-width: 100%;
     min-width: unset !important;
     min-height: unset !important;

     @media (max-width: 320px) and (min-width: 250px) {
      width: 250px !important;
     }
     @media (max-width: 400px) and (min-width: 320px) {
      width: 300px !important;
     }
     @media (max-width: 500px) and (min-width: 400px) {
      width: 380px !important;
     }
     @media (max-width: 600px) and (min-width: 500px) {
      width: 450px !important;
     }
     @media (max-width: 700px) and (min-width: 600px) {
      width: 650px !important;
     }
     @media (max-width: 1000px) and (min-width: 700px) {
      width: 680px !important;
     }
    }
  }

  

  .media-item-modal-photos-large {
    max-height: calc(100% - 20px);
    overflow: hidden;
    max-width: 100%;
    display: block;
    
    span,
    div,
    img {
     position: relative !important;
     height: 600px !important;
     width: auto !important;
     max-height: 100%;
     max-width: 100%;
     min-width: unset !important;
     min-height: unset !important;

    }
  }
}
.photo-library-container {
  .photos-container {
    height: 200;
    position: relative;
    margin: 0px 5px;
    cursor: pointer;
  }
  .photos-container-mobile {
    height: 250;
    position: relative;
    margin: 0px 5px;
    cursor: pointer;
  }
}
