@import 'main.scss';

.table-title {
  color: $primary;
  font-size: 34px;
}
.custom-table {
  font-family: $font_lato_regular;
  .no-flights-container {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .table-cell {
    font-size: 16px;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    &:first-child {
      justify-content: flex-start;
    }
    .price-currency {
      font-family: $font_lato_bold;
      padding-left: 2px;
    }
    svg {
      fill: $primary;
      margin-right: 5px;
      width: 20px;
      .fill-grey {
        fill: $primary;
      }
    }
    .flight-ticket {
      fill: $bestPriceColor;
      width: 24px;
    }
    &.bold {
      font-family: $font_lato_bold;
      padding-right: 5px;
    }
    .subtitle {
      font-size: 10px;
      font-family: $font_lato_regular;
    }
  }
  .btn-container {
    display: flex;
    -moz-box-pack: end;
    justify-content: flex-end;

    .reserve-btn {
      background: $primary;
      color: $white;
      font-family: $font_lato_regular;
      padding: 6px 15px;
      &:hover {
        background: $yellow;
        color: $primary;
      }
    }
  }
  .price {
    justify-content: flex-start;
  }
  .flights-info-container.flights-info-mobile {
    .btn-container {
      display: flex;
      justify-content: center;
      padding: 0 50px;
      padding-top: 10px;
      .reserve-btn {
        width: 100%;
      }
    }
    .date,
    .price {
      justify-content: center;
    }
    .departure,
    .destination {
      flex-basis: 50%;
      width: 50%;
    }
  }
}
