@import 'main.scss';

.paginated-list-container {
  padding: 20px 130px;

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 20px 80px !important;
  }

  @media only screen and (max-width: 1300px) {
    padding: 20px 0.5% !important;
  }

    .candidature-btn {
      background-color: $primary;
      color: $white;
      height: 40px;
      padding: 0 15px;
      font-family: $font_lato_regular;
      margin-top: 10px;
      width: 90%;
      margin-left: 5px;
      width:50%;
      margin-bottom: 20px;
      &:hover {
        background-color: $yellow;
        color: $primary;
      }
    }
  
  &.card-module {
    padding: 0 !important;
  }
  .no-data-container {
    padding: 20px;
    color: $primary;
    font-family: $font_lato_bold;
  }
  .list-item-bloc {
    min-width: 300px;
    min-height: 300px;
    flex-grow: 1;
    padding: 20px;
    @media  (max-width: 1200px) and (min-width: 950px) {
      min-width: 500px !important;
    }
    @media  (max-width: 608px) and (min-width: 598px) {
      min-width: 500px !important;
    }

    &.list-item-bloc-without-button {
      .list-item-container .list-information-item-info-container {
        box-shadow: none;
        cursor: auto;
        &:hover {
          box-shadow: 0px 0px 15px $transparentShadow;
        }
        .jasmin-item-button {
          margin-top: auto;
          button {
            background: $primary;
            color: $white;
            height: 40px;
            padding: 10px 20px;
            font-family: $font_lato_bold;
            &:hover {
              background: $yellow;
              color: $primary;
            }
          }
        }
        .list-information-item-description-container {
          padding-bottom: 20px;
          margin-top: 0;
        }
        .list-information-item-title-container {
          padding-bottom: 20px;
        }
      }
    }
    .button-container {
      margin-top: auto;
    }
    .list-item-container {
      height: 100%;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      @keyframes fadeOutOpacity {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
      @keyframes fadeInOpacity {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .list-item-info-content {
        height: 100%;
      }
      .list-item-info-container {
        min-height: 200px;
        padding: 15px 30px;
        box-shadow: 0px 3px 6px $transparentShadow;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        flex-grow: 1;
        .list-item-title-container {
          font-size: 21px;
          color: $primary;
          font-family: $font_lato_bold;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        .list-item-description-container {
          color: $descriptionGrey;
          font-size: 16px;
          margin: 20px 0;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          text-align: left;
        }
        .link-title {
          background-color: $primary;
          color: $white;
          height: 40px;
          padding: 0 20px;
          font-family: $font_lato_bold;
          &:hover {
            background-color: $yellow;
            color: $primary;
          }
        }
      }
      .list-information-item-info-container {
        text-transform: none;
        cursor: pointer;
        z-index: 1;
        min-height: 300px;
        padding: 20px;
        box-shadow: 0px 0px 15px $transparentShadow;
        border-radius: 5px;
        flex-grow: 1;
        align-items: baseline;
        .list-information-item-title-container {
          font-size: 20px;
          color: $primary;
          font-family: $font_lato_bold;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          text-align: left;
        }
        .list-information-item-icon-container {
          height: 85px;
          align-items: center;
          margin-bottom: 20px;
          position: relative;
          img {
            inset: unset !important;
            min-width: unset !important;
            min-height: unset !important;
            width: auto !important;
            height: auto !important;
          }
          div {
            display: flex !important;
            align-items: center;
          }
        }
        .list-information-item-divide {
          border-top: 2px solid $primary;
          position: relative;
          width: 60px;
          margin-bottom: 20px;
        }
        .list-information-item-description-container {
          color: $descriptionGrey;
          font-size: 15px;
          margin: 10px 0 0;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          text-align: left;
        }
      }
      .list-information-item-info-container-hover {
        z-index: 2;
        background-color: $primary;
        height: 100%;
        padding: 20px;
        box-shadow: 0px 0px 15px $transparentShadow;
        border-radius: 5px;
        flex-grow: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        width: 100%;
        .list-information-item-category-container {
          color: $white;
          margin: 4px 0px;
          cursor: pointer;
          &:hover {
            .item-category-title {
              padding-left: 30px;
              font-family: $font_lato_bold;
            }
            .item-category-all {
              padding-left: 30px;
            }
          }
          .item-category-title {
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-align: left;
            word-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;
            text-transform: uppercase;
            padding-left: 0px;
            font-size: 16px;
            height: 20px;
            font-family: $font_lato_regular;
            transition: all 250ms ease-in-out 0ms;
          }
          .item-category-all {
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-align: left;
            word-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;
            text-transform: uppercase;
            padding-left: 0px;
            font-size: 16px;
            height: 20px;
            font-family: $font_lato_bold;
            transition: all 250ms ease-in-out 0ms;
            &:hover {
              padding-left: 30px;
            }
          }
          .item-category-icon {
            margin-right: auto;
            margin-bottom: auto;
            margin-top: -2px;
          }
        }
        &:hover {
          animation-name: fadeInOpacity;
          animation-iteration-count: 1;
          animation-timing-function: ease-in-out;
          animation-duration: 0.25s;
          &::after {
            animation-name: fadeOutOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
            animation-duration: 0.25s;
          }
        }
      }
      .list-item-img-container {
        position: relative;
        height: 298px;

        @media  (max-width: 1700px) and (min-width: 1450px) {
          height: 230px !important;
        }
        @media  (max-width: 1450px) and (min-width: 1200px) {
          height: 220px !important;
        }
        @media  (max-width: 1200px) and (min-width: 1000px) {
          height: 250px !important;
        }
       
        div:first-of-type {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }
        img {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          transition-duration: 0.2s;
        }
      }
      &:hover {
        .list-item-img-container img {
          transform: scale(1.05);
        }
      }
      .price-info {
        position: relative;
        align-items: end;
        .price-container {
          height: fit-content;
          background: $yellow;
          color: $primary;
          padding: 7px 4px 7px 9px;
          width: fit-content;
          max-width: 100%;
          border-radius: 2px 0px 0px 2px !important;
          margin-top: 15px;
          margin-right: -7.5px;
          .price-content {
            font-family: $font_lato_bold;
            display: flex;
            font-size: 28px;
            .price-currency {
              font-size: 14px;
            }
          }
          .date-content {
            padding-left: 8px;
            font-family: $font_lato_regular;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
    .list-promotion-item-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: none;
      padding: 20px 0px 0px 0px;

      .list-promotion-item-info-container {
        padding: 30px 30px 30px 0px;
        flex-grow: 1;
        width: 35%;
        .list-item-title-container {
          font-size: 28px;
          color: $primary;
          font-family: $font_lato_bold;
        }
        .list-item-description-container {
          font-size: 16px;
          text-align: left;
          margin: 20px 0;
          color: $descriptionGrey;

          .list-item-description {
            -webkit-line-clamp: 7;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
        .link-title {
          background-color: $primary;
          color: $white;
          height: 40px;
          padding: 0 20px;
          font-family: $font_lato_bold;
          &:hover {
            background-color: $yellow;
            color: $primary;
          }
        }
        .list-item-see-more-btn {
          font-size: 16px;
          color: $primary;
        }
      }
      .list-promotion-item-img-container {
        box-shadow: 0px 3px 6px #00000040;
        border-radius: 10px;
        margin: 0px 30px 0px 0px;
        position: relative;
        height: 350px;
        width: 55%;
        img {
          border-radius: 10px;
        }
      }
      .price-info {
        position: relative;
        align-items: end;
        .triangle {
          border-color: $secondYellow transparent transparent $secondYellow;
          border-style: solid;
          border-width: 5px 5px 5px 5px;
          height: 0px;
          width: 0px;
          margin-right: -10px;
          margin-left: auto;
        }
        .price-container {
          height: fit-content;
          background: $yellow;
          color: $primary;
          padding: 7px 4px 7px 9px;
          width: fit-content;
          max-width: 100%;
          border-radius: 2px 0px 0px 2px !important;
          margin-top: 15px;
          margin-right: -10px;
          margin-left: auto;
          .price-content {
            font-family: $font_lato_bold;
            display: flex;
            font-size: 28px;
            .price-currency {
              font-size: 14px;
            }
          }
          .date-content {
            padding-left: 8px;
            font-family: $font_lato_regular;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
    .list-promotion-item-container-mobile {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: none;

      .list-promotion-item-info-container {
        padding: 20px 0px 0px 0px;
        flex-grow: 1;
        .list-item-title-container {
          font-size: 28px;
          color: $primary;
          font-family: $font_lato_bold;
        }
        .list-item-description-container {
          font-size: 16px;
          text-align: left;
          margin: 20px 0;
          color: $descriptionGrey;

          .list-item-description {
            -webkit-line-clamp: 7;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-wrap: break-word;
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
        .link-title {
          background-color: $primary;
          color: $white;
          height: 40px;
          padding: 0 20px;
          font-family: $font_lato_bold;
          &:hover {
            background-color: $yellow;
            color: $primary;
          }
        }
        .list-item-see-more-btn {
          font-size: 16px;
          color: $primary;
        }
      }
      .list-promotion-item-img-container {
        box-shadow: 0px 3px 6px #00000040;
        border-radius: 10px;
        margin: 0px;
        position: relative;
        height: 250px;
        width: 100%;
        img {
          border-radius: 10px;
        }
      }
      .price-info {
        position: relative;
        align-items: end;
        .triangle {
          border-color: $secondYellow transparent transparent $secondYellow;
          border-style: solid;
          border-width: 5px 5px 5px 5px;
          height: 0px;
          width: 0px;
          margin-right: -10px;
          margin-left: auto;
        }
        .price-container {
          height: fit-content;
          background: $yellow;
          color: $primary;
          padding: 7px 4px 7px 9px;
          width: fit-content;
          max-width: 100%;
          border-radius: 2px 0px 0px 2px !important;
          margin-top: 15px;
          margin-right: -10px;
          margin-left: auto;
          .price-content {
            font-family: $font_lato_bold;
            display: flex;
            font-size: 28px;
            .price-currency {
              font-size: 14px;
            }
          }
          .date-content {
            padding-left: 8px;
            font-family: $font_lato_regular;
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .bloc-image-with-text {
    min-width: 300px;
    min-height: 400px;
    flex-grow: 1;
    padding: 20px;

    .card-container {
      display: 'flex';
      flex-direction: 'column';
      justify-content: 'center';
      .image-container {
        min-width: 280px;
        min-height: 200px;
        position: relative !important;
      }
      .text-container {
        display: 'flex';
        flex-direction: 'column';
        justify-content: 'center';
        min-width: 280px;
        min-height: 200px;
        padding: 10px;
        .title-bloc {
          font-size: 21px;
          color: $primary;
          font-family: $font_lato_bold;
          text-align: left;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        .description-bloc {
          margin-top: 10px;
          overflow-wrap: break-word;
          color: $descriptionGrey;
          font-size: 16px;
          margin: 20px 0;
          -webkit-line-clamp: 4;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          text-align: left;
        }
      }
    }
  }
}

.paginated-list-container-mobile {
  .candidature-btn {
    background-color: $primary;
    color: $white;
    height: 40px;
    padding: 0 15px;
    font-family: $font_lato_regular;
    margin-top: 10px;
    width: 90%;
    margin-left: 5px;
    width:80%;
    &:hover {
      background-color: $yellow;
      color: $primary;
    }
  }
  .button-container button.link-title {
    width: 100%;
  }
  .paginated-list-container {
        .list-item-bloc {
          .list-item-container {
            .list-item-img-container {
              position: relative;
              height: 250px !important;
            }
          }
       }
}
}
