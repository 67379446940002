@import 'main.scss';

.information-manage-bloc-container {
  position: relative;
  justify-content: center;
  margin-bottom: 60px;
  .MuiTabPanel-root {
    padding: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .information-grid-tab-panel {
    width: 100%;
    .no-data-container {
      padding-top: 40px;
    }
    .paginated-list-container {
      padding: 20px 130px;
    
      @media only screen and (max-width: 1500px) and (min-width: 1300px) {
        padding: 20px 80px !important;
      }
    
      @media only screen and (max-width: 1300px) {
        padding: 20px 0.5% !important;
      }
    
        .candidature-btn {
          background-color: $primary;
          color: $white;
          height: 40px;
          padding: 0 15px;
          font-family: $font_lato_regular;
          margin-top: 10px;
          width: 90%;
          margin-left: 5px;
          width:50%;
          margin-bottom: 20px;
          &:hover {
            background-color: $yellow;
            color: $primary;
          }
        }
      
      &.card-module {
        padding: 0 !important;
      }
      .no-data-container {
        padding: 20px;
        color: $primary;
        font-family: $font_lato_bold;
      }
      .list-item-bloc {
        min-width: 300px;
        min-height: 300px;
        flex-grow: 1;
        padding: 20px;
        @media  (max-width: 1200px) and (min-width: 950px) {
          min-width: 500px !important;
        }
        @media  (max-width: 608px) and (min-width: 598px) {
          min-width: 500px !important;
        }
    
        &.list-item-bloc-without-button {
          .list-item-container .list-information-item-info-container {
            box-shadow: none;
            cursor: auto;
            &:hover {
              box-shadow: 0px 0px 15px $transparentShadow;
            }
            .jasmin-item-button {
              margin-top: auto;
              button {
                background: $primary;
                color: $white;
                height: 40px;
                padding: 10px 20px;
                font-family: $font_lato_bold;
                &:hover {
                  background: $yellow;
                  color: $primary;
                }
              }
            }
            .list-information-item-description-container {
              padding-bottom: 20px;
              margin-top: 0;
            }
            .list-information-item-title-container {
              padding-bottom: 20px;
            }
          }
        }
        .button-container {
          margin-top: auto;
        }
        .list-item-container {
          height: 100%;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          box-shadow: none;
          @keyframes fadeOutOpacity {
            0% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
          @keyframes fadeInOpacity {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          .list-item-info-content {
            height: 100%;
          }
          .list-item-info-container {
            min-height: 200px;
            padding: 15px 30px;
            box-shadow: 0px 3px 6px $transparentShadow;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            flex-grow: 1;
            .list-item-title-container {
              font-size: 21px;
              color: $primary;
              font-family: $font_lato_bold;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
            }
            .list-item-description-container {
              color: $descriptionGrey;
              font-size: 16px;
              margin: 20px 0;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
              text-align: left;
            }
            .link-title {
              background-color: $primary;
              color: $white;
              height: 40px;
              padding: 0 20px;
              font-family: $font_lato_bold;
              &:hover {
                background-color: $yellow;
                color: $primary;
              }
            }
          }
          .list-information-item-info-container {
            text-transform: none;
            cursor: pointer;
            z-index: 1;
            min-height: 300px;
            padding: 20px;
            box-shadow: 0px 0px 15px $transparentShadow;
            border-radius: 5px;
            flex-grow: 1;
            align-items: baseline;
            .list-information-item-title-container {
              font-size: 20px;
              color: $primary;
              font-family: $font_lato_bold;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
              text-align: left;
            }
            .list-information-item-icon-container {
              height: 85px;
              align-items: center;
              margin-bottom: 20px;
              position: relative;
              img {
                inset: unset !important;
                min-width: unset !important;
                min-height: unset !important;
                width: auto !important;
                height: auto !important;
              }
              div {
                display: flex !important;
                align-items: center;
              }
            }
            .list-information-item-divide {
              border-top: 2px solid $primary;
              position: relative;
              width: 60px;
              margin-bottom: 20px;
            }
            .list-information-item-description-container {
              color: $descriptionGrey;
              font-size: 15px;
              margin: 10px 0 0;
              -webkit-line-clamp: 3;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
              text-align: left;
            }
          }
          .list-information-item-info-container-hover {
            z-index: 2;
            background-color: $primary;
            height: 100%;
            padding: 20px;
            box-shadow: 0px 0px 15px $transparentShadow;
            border-radius: 5px;
            flex-grow: 1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            width: 100%;
            .list-information-item-category-container {
              color: $white;
              margin: 4px 0px;
              cursor: pointer;
              &:hover {
                .item-category-title {
                  padding-left: 30px;
                  font-family: $font_lato_bold;
                }
                .item-category-all {
                  padding-left: 30px;
                }
              }
              .item-category-title {
                -webkit-line-clamp: 1;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
                text-transform: uppercase;
                padding-left: 0px;
                font-size: 16px;
                height: 20px;
                font-family: $font_lato_regular;
                transition: all 250ms ease-in-out 0ms;
              }
              .item-category-all {
                -webkit-line-clamp: 1;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
                text-transform: uppercase;
                padding-left: 0px;
                font-size: 16px;
                height: 20px;
                font-family: $font_lato_bold;
                transition: all 250ms ease-in-out 0ms;
                &:hover {
                  padding-left: 30px;
                }
              }
              .item-category-icon {
                margin-right: auto;
                margin-bottom: auto;
                margin-top: -2px;
              }
            }
            &:hover {
              animation-name: fadeInOpacity;
              animation-iteration-count: 1;
              animation-timing-function: ease-in-out;
              animation-duration: 0.25s;
              &::after {
                animation-name: fadeOutOpacity;
                animation-iteration-count: 1;
                animation-timing-function: ease-in-out;
                animation-duration: 0.25s;
              }
            }
          }
          .list-item-img-container {
            position: relative;
            height: 298px;
    
            @media  (max-width: 1700px) and (min-width: 1450px) {
              height: 230px !important;
            }
            @media  (max-width: 1450px) and (min-width: 1200px) {
              height: 220px !important;
            }
            @media  (max-width: 1200px) and (min-width: 1000px) {
              height: 250px !important;
            }
           
            div:first-of-type {
              border-top-right-radius: 5px;
              border-top-left-radius: 5px;
            }
            img {
              border-top-right-radius: 5px;
              border-top-left-radius: 5px;
              transition-duration: 0.2s;
            }
          }
          &:hover {
            .list-item-img-container img {
              transform: scale(1.05);
            }
          }
          .price-info {
            position: relative;
            align-items: end;
            .price-container {
              height: fit-content;
              background: $yellow;
              color: $primary;
              padding: 7px 4px 7px 9px;
              width: fit-content;
              max-width: 100%;
              border-radius: 2px 0px 0px 2px !important;
              margin-top: 15px;
              margin-right: -7.5px;
              .price-content {
                font-family: $font_lato_bold;
                display: flex;
                font-size: 28px;
                .price-currency {
                  font-size: 14px;
                }
              }
              .date-content {
                padding-left: 8px;
                font-family: $font_lato_regular;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
          }
        }
        .list-promotion-item-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          box-shadow: none;
          padding: 20px 0px 0px 0px;
    
          .list-promotion-item-info-container {
            padding: 30px 30px 30px 0px;
            flex-grow: 1;
            width: 35%;
            .list-item-title-container {
              font-size: 28px;
              color: $primary;
              font-family: $font_lato_bold;
            }
            .list-item-description-container {
              font-size: 16px;
              text-align: left;
              margin: 20px 0;
              color: $descriptionGrey;
    
              .list-item-description {
                -webkit-line-clamp: 7;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              }
            }
            .link-title {
              background-color: $primary;
              color: $white;
              height: 40px;
              padding: 0 20px;
              font-family: $font_lato_bold;
              &:hover {
                background-color: $yellow;
                color: $primary;
              }
            }
            .list-item-see-more-btn {
              font-size: 16px;
              color: $primary;
            }
          }
          .list-promotion-item-img-container {
            box-shadow: 0px 3px 6px #00000040;
            border-radius: 10px;
            margin: 0px 30px 0px 0px;
            position: relative;
            height: 350px;
            width: 55%;
            img {
              border-radius: 10px;
            }
          }
          .price-info {
            position: relative;
            align-items: end;
            .triangle {
              border-color: $secondYellow transparent transparent $secondYellow;
              border-style: solid;
              border-width: 5px 5px 5px 5px;
              height: 0px;
              width: 0px;
              margin-right: -10px;
              margin-left: auto;
            }
            .price-container {
              height: fit-content;
              background: $yellow;
              color: $primary;
              padding: 7px 4px 7px 9px;
              width: fit-content;
              max-width: 100%;
              border-radius: 2px 0px 0px 2px !important;
              margin-top: 15px;
              margin-right: -10px;
              margin-left: auto;
              .price-content {
                font-family: $font_lato_bold;
                display: flex;
                font-size: 28px;
                .price-currency {
                  font-size: 14px;
                }
              }
              .date-content {
                padding-left: 8px;
                font-family: $font_lato_regular;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
          }
        }
        .list-promotion-item-container-mobile {
          height: 100%;
          display: flex;
          flex-direction: column;
          box-shadow: none;
    
          .list-promotion-item-info-container {
            padding: 20px 0px 0px 0px;
            flex-grow: 1;
            .list-item-title-container {
              font-size: 28px;
              color: $primary;
              font-family: $font_lato_bold;
            }
            .list-item-description-container {
              font-size: 16px;
              text-align: left;
              margin: 20px 0;
              color: $descriptionGrey;
    
              .list-item-description {
                -webkit-line-clamp: 7;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              }
            }
            .link-title {
              background-color: $primary;
              color: $white;
              height: 40px;
              padding: 0 20px;
              font-family: $font_lato_bold;
              &:hover {
                background-color: $yellow;
                color: $primary;
              }
            }
            .list-item-see-more-btn {
              font-size: 16px;
              color: $primary;
            }
          }
          .list-promotion-item-img-container {
            box-shadow: 0px 3px 6px #00000040;
            border-radius: 10px;
            margin: 0px;
            position: relative;
            height: 250px;
            width: 100%;
            img {
              border-radius: 10px;
            }
          }
          .price-info {
            position: relative;
            align-items: end;
            .triangle {
              border-color: $secondYellow transparent transparent $secondYellow;
              border-style: solid;
              border-width: 5px 5px 5px 5px;
              height: 0px;
              width: 0px;
              margin-right: -10px;
              margin-left: auto;
            }
            .price-container {
              height: fit-content;
              background: $yellow;
              color: $primary;
              padding: 7px 4px 7px 9px;
              width: fit-content;
              max-width: 100%;
              border-radius: 2px 0px 0px 2px !important;
              margin-top: 15px;
              margin-right: -10px;
              margin-left: auto;
              .price-content {
                font-family: $font_lato_bold;
                display: flex;
                font-size: 28px;
                .price-currency {
                  font-size: 14px;
                }
              }
              .date-content {
                padding-left: 8px;
                font-family: $font_lato_regular;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .bloc-image-with-text {
        min-width: 300px;
        min-height: 400px;
        flex-grow: 1;
        padding: 20px;
    
        .card-container {
          display: 'flex';
          flex-direction: 'column';
          justify-content: 'center';
          .image-container {
            min-width: 280px;
            min-height: 200px;
            position: relative !important;
          }
          .text-container {
            display: 'flex';
            flex-direction: 'column';
            justify-content: 'center';
            min-width: 280px;
            min-height: 200px;
            padding: 10px;
            .title-bloc {
              font-size: 21px;
              color: $primary;
              font-family: $font_lato_bold;
              text-align: left;
              -webkit-line-clamp: 2;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
            }
            .description-bloc {
              margin-top: 10px;
              overflow-wrap: break-word;
              color: $descriptionGrey;
              font-size: 16px;
              margin: 20px 0;
              -webkit-line-clamp: 4;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;
              text-align: left;
            }
          }
        }
      }
    }
  }
  .information-control {
    padding-top: 20px;

    .information-control-tab {
      min-width: 230px;
      max-width: 100%;
      width: auto;
    }
    .MuiTabs-root {
      border-radius: 5px;
      background-color: $hoverColor;
      min-height: unset;
      padding: 5px;
      margin-top: 20px;
      .MuiTabs-scroller {
        min-height: unset;
        height: fit-content;
      }
      button.MuiTab-root {
        opacity: 1;
        height: 40px;
        padding: 0;
        min-height: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
      }
      button.MuiTab-root span.MuiTab-wrapper {
        z-index: 1;
        color: $primary;
        font-family: $font_lato_bold;
        font-size: 14px;
        flex-direction: row;
        svg {
          margin-right: 5px;
          .fill-grey {
            fill: $primary;
          }
          .stroke-grey {
            stroke: $primary;
          }
        }
      }
      button.Mui-selected span.MuiTab-wrapper {
        color: $white;
        svg {
          fill: $white;
          .fill-blue,
          .fill-grey {
            fill: $white;
          }
          .stroke-grey {
            stroke: $white;
          }
        }
      }
      .MuiTabs-indicator {
        height: 100%;
        background-color: $primary;
        z-index: 0;
        border-radius: 5px;
      }
    }
  }
  .information-manage-bloc-content {
    min-width: 80%;
    max-width: 100%;
    width: auto;
    margin-top: -80px;
    background: $white;
    box-shadow: 0px 0px 15px $transparentShadow;
    border-radius: 5px;
    min-height: 250px;

    button.MuiTab-root {
      width: 33.3333%;
      max-width: 100%;
      opacity: 1;
      position: relative;
      font-family: $font_lato_bold;
      font-size: 14px;
      svg {
        margin-right: 9px;
        .fill-blue {
          fill: $white;
        }
        .fill-white {
          fill: $primary;
        }
        &.Done-icon {
          .fill-blue {
            fill: $primary;
          }
          .fill-white {
            fill: $white;
          }
        }
      }
      &.Mui-selected {
        background: $white;
        color: $primary;
        font-family: $font_lato_regular;
        svg {
          .fill-blue {
            fill: $primary;
          }
          .fill-white {
            fill: $white;
          }
          &.Done-icon {
            .fill-blue {
              fill: $white;
            }
            .fill-white {
              fill: $primary;
            }
          }
        }
      }
      hr.left-divider {
        position: absolute;
        left: 0;
        height: 10px;
        top: 40%;
        background-color: $white;
      }
      hr.right-divider {
        position: absolute;
        right: 0;
        height: 10px;
        top: 40%;
        background-color: $white;
      }
    }
    .MuiTabs-indicator {
      background: transparent;
      display: flex;
      justify-content: center;
      span {
        max-width: 20px;
        width: 100%;
        background-color: $primary;
      }
    }
  }
  .faq-bloc-content {
    width: 68%;
    display: inline-grid;
    padding-top: 30px;
    .no-data-container {
      display: flex;
      text-align: center;
      justify-content: center;
      padding: 20px;
      color: $primary;
      font-family: $font_lato_bold;
    }
    .MuiAccordion-root:before {
      top: 0px;
      left: 0;
      right: 0;
      height: 0;
      content: '';
      opacity: 0;
      position: absolute;
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: rgba(0, 0, 0, 0.12);
    }
    .MuiAccordion-root.Mui-expanded:first-child {
      margin-top: 10px;
    }
    .MuiAccordion-root {
      border-radius: 5px;
      min-height: 64px;
    }
    .faq-bloc-item {
      margin: 10px;
      box-shadow: 0px 0px 15px $transparentShadow;

      .MuiAccordionSummary-root {
        padding: 0px 20px;
        height: 64px;
        color: $primary;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: min-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        .MuiTypography-body1 {
          font-size: 21px;
          font-family: $font_lato_bold;
          transition: all 0.5s ease-in-out;
          animation: reverseTitleTransition 0.5s;
        }
        .category-image {
          position: relative;
          height: 30px;
          width: 30px;
        }

        .add-icon {
          display: flex;
          align-items: baseline;
          color: $primary;
        }
        .MuiAccordionSummary-expandIcon.Mui-expanded {
          transform: unset;
        }
        svg {
          animation: rotatePlus 1s;
        }

        &:hover {
          background: unset;
          svg {
            animation: reverseRotatePlus 1s forwards;
          }
          &:before,
          &:after {
            width: 100%;
            opacity: 1;
          }
        }
        @keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        @keyframes titleTransition {
          from {
            margin-left: -30px;
          }
          to {
            margin-left: 13px;
          }
        }
        @keyframes reverseTitleTransition {
          from {
            margin-left: 43px;
          }
          to {
            margin-left: 0px;
          }
        }
        @keyframes rotatePlus {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(90deg);
          }
        }

        @keyframes reverseRotatePlus {
          from {
            transform: rotate(90deg);
          }
          to {
            transform: rotate(0deg);
          }
        }
      }
      .MuiAccordionSummary-root.Mui-expanded {
        background-color: $primary;
        height: 64px;
        color: $white;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .MuiTypography-body1 {
          animation: titleTransition 1s forwards;
        }
        .category-image {
          animation: fadein 1s forwards;
        }
        .add-icon {
          display: flex;
          align-items: baseline;
          color: $white;
        }
        svg {
          animation: unset;
        }
        &:hover {
          svg {
            animation: unset;
          }
        }
      }
      .MuiAccordionDetails-root {
        display: block;
        padding: 0px 20px 0px 20px;

        .MuiAccordion-root:after {
          top: -1px;
          left: 0;
          right: 0;
          height: 1px;
          content: '';
          opacity: 1;
          position: absolute;
          transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-color: rgba(0, 0, 0, 0.12);
        }
        .faq-bloc-content-sub-expand {
          margin: 0;
          flex-shrink: 0;
          box-shadow: unset;

          .MuiAccordionSummary-root {
            flex-direction: row;
            .MuiSvgIcon-root {
              fill: $primary;
            }
            .MuiAccordionSummary-expandIcon.Mui-expanded {
              transform: rotate(90deg);
            }
          }
          .MuiAccordionSummary-root {
            .MuiTypography-body1 {
              font-size: 14px;
              font-family: $font_lato_regular;
            }
          }
          .MuiAccordionSummary-root.Mui-expanded {
            min-height: 64px;
            background-color: $white;
            color: $primary;
          }
          svg {
            animation: unset;
          }
          &:hover {
            svg {
              animation: unset;
            }
          }
          .MuiTypography-body1 {
            animation: unset;
          }
          .MuiAccordionDetails-root {
            margin: 0px 0px 15px 15px;
            padding: 0px 0px 0px 16px;
            .MuiAccordion-root:after {
              top: 0px;
              left: 0;
              right: 0;
              height: 0;
              content: '';
              opacity: 1;
              position: absolute;
              transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              background-color: rgba(0, 0, 0, 0.12);
            }
            .MuiAccordion-root:before {
              top: 0px;
              left: 0;
              right: 0;
              height: 0;
              content: '';
              opacity: 1;
              position: absolute;
              transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              background-color: rgba(0, 0, 0, 0.12);
            }
            .MuiAccordion-root {
              border-radius: 5px;
              background-color: $backgroundWhiteLilac;
            }
            .faq-bloc-content-sub-faq-expand {
              flex-shrink: 0;
              margin: 0px 10px 20px 10px;
              box-shadow: unset;
              min-height: 64px;
              .MuiAccordionSummary-root {
                height: auto;
                min-height: 64px;
                border-radius: 5px;
                flex-direction: row;
                box-shadow: unset;
                background-color: $backgroundWhiteLilac;
                .MuiSvgIcon-root {
                  fill: $primary;
                }
              }
              .MuiAccordionSummary-root.Mui-expanded {
                position: relative;
                z-index: 1;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  height: 1px;
                  width: 60px;
                  margin-left: 20px;
                  border-bottom: 1px solid $primary;
                }
              }
              .MuiAccordionDetails-root {
                margin: 20px 10px 20px 5px;
                .MuiTypography-body1 {
                  font-size: 14px;
                  font-family: $font_lato_regular;
                  .custom-page-html-div {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
        .direct-faq {
          .MuiAccordionSummary-root {
            flex-direction: row-reverse;
            .MuiSvgIcon-root {
              fill: $primary;
            }
            .MuiAccordionSummary-expandIcon.Mui-expanded {
              transform: rotate(90deg);
            }
          }
          .MuiAccordionSummary-root {
            .MuiTypography-body1 {
              font-size: 14px;
              font-family: $font_lato_regular;
            }
          }
          .MuiAccordionSummary-root.Mui-expanded {
            min-height: 64px;
            background-color: $white;
            color: $primary;
          }
          svg {
            animation: unset;
          }
          &:hover {
            svg {
              animation: unset;
            }
          }
          .MuiTypography-body1 {
            animation: unset;
          }
          margin: 15px 0px 15px 0px;
          padding: 0px 0px 0px 16px;
          .MuiAccordion-root:after {
            top: 0px;
            left: 0;
            right: 0;
            height: 0;
            content: '';
            opacity: 1;
            position: absolute;
            transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: rgba(0, 0, 0, 0.12);
          }
          .MuiAccordion-root:before {
            top: 0px;
            left: 0;
            right: 0;
            height: 0;
            content: '';
            opacity: 1;
            position: absolute;
            transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            background-color: rgba(0, 0, 0, 0.12);
          }
          .MuiAccordion-root {
            border-radius: 5px;
            background-color: $backgroundWhiteLilac;
          }
          .faq-bloc-content-faq-expand {
            flex-shrink: 0;
            margin: 0px 10px 20px 10px;
            box-shadow: unset;
            min-height: 64px;
            .MuiAccordionSummary-root {
              height: auto;
              min-height: 64px;
              border-radius: 5px;
              flex-direction: row;
              box-shadow: unset;
              background-color: $backgroundWhiteLilac;
              .MuiSvgIcon-root {
                fill: $primary;
              }
            }
            .MuiAccordionSummary-root.Mui-expanded {
              position: relative;
              z-index: 1;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                width: 60px;
                margin-left: 20px;
                border-bottom: 1px solid $primary;
              }
            }
            .MuiAccordionDetails-root {
              margin: 20px 10px 20px 5px;
              .MuiTypography-body1 {
                font-size: 14px;
                font-family: $font_lato_regular;
                .custom-page-html-div {
                  color: $primary;
                }
              }
            }
          }
        }
      }
    }
    .faq-bloc-content-sub-faq-expand {
      flex-shrink: 0;
      margin: 0px 10px 20px 10px;
      box-shadow: unset;
      min-height: 64px;
       .MuiAccordion-root {
      border-radius: 5px;
      background-color: $backgroundWhiteLilac;
    }
      .MuiAccordionSummary-root {
        height: auto;
        min-height: 64px;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: unset;
        background-color: $backgroundWhiteLilac;
        .MuiSvgIcon-root {
          fill: $primary;
        }
      }
      .MuiAccordionSummary-root.Mui-expanded {
        position: relative;
        z-index: 1;
        .MuiTypography-body1 {
          font-size: 14px;
          font-family: $font_lato_regular;
          color: $primary;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 60px;
          margin-left: 20px;
          border-bottom: 1px solid $primary;
         
        }
      }
      .MuiAccordionDetails-root {
        margin: 20px 10px 20px 5px;
        background-color: $backgroundWhiteLilac;
        .MuiTypography-body1 {
          font-size: 14px;
          font-family: $font_lato_regular;
          .custom-page-html-div {
            color: $primary;
          }
        }
      }
    }
  }
}

.information-manage-bloc-container-mobile {
  .faq-bloc-content {
    width: 95%;
    display: block;
    .faq-bloc-item {
      margin: 15px 10px;
    }
  }

  .information-control {
    .information-control-tab {
      min-width: unset;
      max-width: 100%;
      height: auto !important;
      width: 50%;
    }
    .MuiTabs-root {
      .MuiTabs-indicator {
        transition-duration: 0s !important;
      }
    }
  }
  .information-control .MuiTabs-root {
    width: 100%;
    button.MuiTab-root {
      background-color: $hoverColor;
      border-radius: 5px;
      padding: 5px 10px;
      svg {
        margin-bottom: 0 !important;
        max-width: 20px;
        max-height: 19px;
      }
    }
  }
}
.information-center-call-container {
  overflow: hidden;
  min-height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 30px 60px;
  border-radius: 5px;
  .image-content {
    z-index: 1;
    overflow: hidden;
    width: 100%;
    .logo-icon {
      display: flex;
      justify-content: flex-end;
      path {
        fill: $primary;
      }
    }
    .mini-logo-icon {
      position: absolute;
      height: 100%;
      opacity: 0.25;
      right: -35px;
      bottom: -10px;
      path {
        fill: $primary;
      }
    }
    img {
      max-height: 275px;
      border-radius: 5px;
    }
    .center-call-image {
      mix-blend-mode: multiply !important;
    }
    .contact-content {
      color: $primary;
      .contact-content-info {
        height: 100%;
      }
      .title {
        font-family: $font_lato_bold;
        font-size: 28px;
        padding: 10px 0px;
      }
      .description {
        font-size: 14px;
        padding-bottom: 10px;
      }
      .contact-button {
        .MuiButton-text.btn {
          font-family: $font_lato_bold;
          background-color: $primary;
          color: $white;
          font-size: 14px;
          padding: 8px 15px;
          &:hover {
            box-shadow: 0px 5px 10px $transparentShadow;
            background-color: $white;
            color: $primary;
          }
        }
      }
    }
  }
}
.information-center-call-container-mobile {
  overflow: hidden;
  min-height: 645px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 35px;
  border-radius: 5px;

  .image-content {
    overflow: hidden;
    text-align: center;

    .logo-icon {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      path {
        fill: $primary;
      }
    }
    img {
      max-height: 650px;
      border-radius: 5px;
      background: $yellow;
    }
    .center-call-image {
      mix-blend-mode: multiply !important;
    }
    .contact-content {
      position: relative;
      color: $primary;
      text-align: center;
      .title {
        font-size: 28px;
        font-family: $font_lato_bold;
        padding: 10px 0px;
      }
      .description {
        font-size: 14px;
        padding-bottom: 10px;
      }
      .contact-button {
        .MuiButton-text.btn {
          font-size: 14px;
          font-family: $font_lato_bold;
          background-color: $primary;
          color: $white;
          padding: 8px 15px;
          width: 100%;
          &:hover {
            box-shadow: 0px 5px 10px $transparentShadow;
            background-color: $white;
            color: $primary;
          }
        }
      }
    }
  }
}
