@import 'main.scss';

.partners-list-container {
  padding: 50px 150px;

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 50px 100px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 50px 20px;
  }

  .no-data-container {
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 20px;
    color: $primary;
    font-family: $font_lato_bold;
  }
  .MuiTabPanel-root {
    width: 100%;
    padding-bottom: 50px;
  }
  .partner-detail-container {
    padding: 20px 0;
    .partner-title {
      color: $primary;
    }
    .partner-image-container {
      position: relative;
      height: 100px;
    }
    .partner-description {
      padding-top: 15px;
    }
    .partner-detail-content {
      padding: 0 20px;
    }
    .partner-title-container,
    .partner-image-container {
      cursor: pointer;
    }
    &.partner-detail-container-mobile {
      .partner-description,
      .partner-title-container {
        justify-content: center;
        text-align: center;
        cursor: pointer;
      }
      .partner-image-container {
        margin-bottom: 20px;
      }
    }
  }
  .MuiTab-root,
  .MuiTabScrollButton-root {
    color: $primary;
    font-family: $font_lato_bold;
  }
  .MuiTabs-indicator {
    background: transparent;
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    span {
      max-width: 20px;
      width: 100%;
      background-color: $yellow;
    }
  }
  .gallery-slider {
    height: 100px;
    margin: 0;
    .slick-next-arrow {
      right: 0;
    }
    .slick-prev-arrow {
      left: 0;
    }
    .slick-next-arrow,
    .slick-prev-arrow {
      top: 50%;
      bottom: unset;
    }
    .slick-slider {
      min-width: 100%;
      text-align: center;
      .slick-track {
        display: flex;
        align-items: center;
        min-height: 100px;
      }
      .slick-center {
        z-index: 1 !important;
        .gallery-slider-item {
          height: 100px;
          border-radius: 0;
          width: 100% !important;
          margin-left: 0;
          box-shadow: unset;
          display: flex !important;
          align-items: center;
          justify-content: center;
          color: $primary;
          opacity: 1;
          h2 {
            font-size: 17px;
          }
          hr.title-divider {
            opacity: 1;
          }
        }
      }
      .slick-slide {
        z-index: -1;
      }
      .gallery-slider-item {
        height: 100px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: $primary;
        opacity: 0.7;
        font-family: $font_lato_regular;
        cursor: pointer;
        flex-direction: column;
        h2 {
          font-size: 15px;
        }
        hr.title-divider {
          background-color: $yellow;
          width: 20px;
          height: 2px;
          opacity: 0;
          margin-top: 5px;
        }
      }
    }
  }
}
