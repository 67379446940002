@import 'main.scss';
.comming-soon-container {
  padding: 100px 50px;
  h2 {
    color: $primary;
    font-family: $font_lato_bold;
  }
}
.page-builder-container {
  padding: 25px 0;

  .section-item {
    margin: 15px 0;
    padding: 0 150px;

    @media only screen and (max-width: 1500px) and (min-width: 1300px) {
      padding: 0 100px;
    }

    @media only screen and (max-width: 1300px) {
      padding: 0 20px;
    }
    &.full-width-section {
      padding: 0;
      margin: 0;

      .module-item {
        margin: 10px 0 !important;
        max-width: 100% !important;
      }
    }
    .section-title {
      font-size: 20px;
      font-family: $font_lato_bold;
      color: $primary;
      padding: 10px;
      // text-align: center;
    }
    .section-description {
      font-size: 16px;
      text-align: center;
      color: $descriptionGrey;
      padding: 10px;
    }
    .module-item {
      width: 100%;
      margin: 10px;
      display: flex;
      position: relative;
      max-width: calc(100% - 20px);
      flex-basis: 1px;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-bottom: 10px;
      .information-manage-bloc-container {
        margin: 0;
        .faq-bloc-content {
          width: 100%;
          padding: 0;
        }
      }
      .flight-info-details-container {
        padding-bottom: 0;
      }
      .paginated-gallery-content {
        padding-top: 0;
      }
      .flights-info-container {
        padding: 20px 0;
      }
      .home-actualities-container {
        padding: 15px 0 !important;
      }
      .flight-manage-bloc-container .flight-manage-bloc-content {
        margin-top: 0;
      }
      .manage-flight-bloc.MuiGrid-container {
        width: 100%;
      }
      .module-image {
        position: relative;
        min-height: 200px;
        * {
          position: relative !important;
        }
        img {
          max-height: 100% !important;
          height: auto !important;
          width: 100% !important;
          border-radius: 10px;
        }
      }
    }
  }

  &.page-builder-container-mobile {
    .section-item {
      .module-item {
        flex-grow: unset !important;
        flex-basis: unset !important;
        width: 100%;
        max-width: 100%;
        margin: 0;
        .flight-manage-bloc-container-module {
          width: 100vw;
          max-width: unset;
          .flight-manage-bloc-content {
            width: 100vw;
            max-width: unset;
          }
        }
      }
    }
  }
  @media (max-width: 1050px) {
    .section-item,
    .module-item {
      .flight-manage-bloc-container-module {
        width: 100vw;
        max-width: unset;
        .flight-manage-bloc-content {
          width: 100vw;
          max-width: unset;
        }
      }
    }
  }

  .with-shadow {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
  }
  .direction-row {
    display: 'flex';
    flex-direction: 'row';
    flex-wrap: 'wrap';
    justify-content: 'space-between';
  }
  .direction-column {
    display: 'flex';
    flex-direction: 'column';
    flex-wrap: 'wrap';
    justify-content: 'space-between';
  }
}
