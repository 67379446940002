@import 'main.scss';

.custom-page-container {
  .page-loader {
    color: $primary;
    margin-top: 300px;
    margin-bottom: 150px;
  }
  .custom-page-content-mobile {
    .custom-page {
      padding: 20px;
      .custom-page-description {
        font-size: 14px;
        text-align: left;
        padding: 20px 0;
        line-height: 22px;
      }
    }
  }
  .custom-page {
    padding: 40px 150px;
    .destination-detail-title {
      color: $primary;
      font-size: 34px;
      font-family: $font_lato_bold;
      text-align: center;
      justify-content: center;
    }
    .destination-detail-description {
      color: $descriptionGrey;
      font-size: 16px;
      text-align: center;
      max-width: 70%;
      padding-top: 30px;
      line-height: 24px;
      justify-content: center;
    }
    @media only screen and (max-width: 1500px) and (min-width: 1300px) {
      padding: 40px 100px;
    }

    @media only screen and (max-width: 1300px) {
      padding: 40px 0.5%;
    }
    .custom-page-description {
      font-size: 16px;
      color: $descriptionGrey;
      text-align: center;
      padding: 30px 0;
      width: 100%;
      line-height: 24px;
    }
  }
}
.custom-page-html-div {
  width: 100%;
  white-space: normal;
  overflow: hidden;

  img {
    max-width: 100%;
    object-fit: cover;
  }
}
