@import 'main.scss';

html {
  padding: 0 !important;
  margin: 0;
}
body {
  padding: 0 !important;
  margin: 0;
  font-family: $font_lato_regular;
  white-space: pre-line !important;
  scroll-behavior: smooth;
  overflow: hidden;
  overflow-y: auto;
  background: $white;
}

#page-container {
  opacity: 0;
  z-index: -1;
  overflow-x: hidden;
  height: 0;
}

.MuiSnackbar-root {
  z-index: 1302 !important;
}

#page-container.full-opacity {
  opacity: 1;
  z-index: unset;
  height: auto;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.MuiPagination-root {
  padding-top: 20px;
  .MuiPaginationItem-root {
    color: $primary;
    font-family: $font_lato_bold;
    font-size: 14px;
    .MuiPaginationItem-icon {
      font-size: 25px;
    }
    &.Mui-selected {
      background-color: $yellow !important;
      height: 40px;
      width: 40px;
      border-radius: 100%;
    }
  }
}

.stroke-grey {
  fill: none;
  stroke: $descriptionGrey;
}
.fill-grey {
  fill: $grey;
}
.fill-white {
  fill: $white;
}
.fill-blue {
  fill: $primary;
}
.fill-yellow {
  fill: $yellow;
}
.fill-none {
  fill: none;
}
.fill-service {
  fill: $serviceColor;
}

.fill-cloud {
  opacity: 0.1;
}
.fill-map {
  opacity: 0.04;
}

.MuiBackdrop-root {
  background-color: $backdropColor !important;
}

.hidden {
  display: none;
}

.show {
  display: block !important;
}

.overlay-svg {
  opacity: 0.4;
  fill: url('#overlay-svg');
}

.page-without-header {
  padding-top: 200px;
  min-height: 600px;
}

.popper-style:after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid transparent;
  border-bottom: 4px solid $white;
  right: 50%;
  bottom: -20px;
  animation: popper-opacity 0.7s forwards;
  opacity: 0;
  .slider-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.menu-dropdown {
  background: transparent;
  padding-top: 20px;
  &.full-width {
    position: absolute;
    left: -5px !important;
    animation: fadeInFromNone 0.5s ease-out;
    .MuiPaper-root {
      border-radius: 0;
    }
  }
}
.menu-links button.link-title {
  z-index: 1;
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0.5;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.blue-button {
  background: $primary;
  color: $white;
}

@keyframes popper-opacity {
  100% {
    opacity: 1;
  }
}
.download-app-btn {
  min-width: 135px;
}
.blue-button.download-app-btn {
  text-transform: none;
  background: $primary;
  min-width: unset;
  padding: 5px 8px;
  line-height: unset;
  height: fit-content;
  text-align: left;
  color: $white !important;
  border-radius: 7px;
  &.google-button {
    margin-top: 10px;
  }
  &.apple-button {
    margin-right: 20px;
    margin-top: 10px;
  }
  .google-button-download-txt,
  .apple-button-download-txt {
    font-size: 10px;
    line-height: 10px;
    font-family: $font_lato_regular;
  }
  .google-button-txt {
    font-family: $font_catull_regular;
    font-size: 15px;
  }
  .apple-button-txt {
    font-family: $font_lato_bold;
    font-size: 15px;
  }
  svg {
    margin-right: 5px;
    width: 23px;
    fill: $white;
  }
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: $hoverColor !important;
}

.page-container {
  position: relative;
}

.close-btn-svg {
  fill: $primary;
  stroke: $lighterPrimary;
  stroke-width: 0.5px;
}

.airplane-global-track,
.airplane-track-svg {
  fill: none;
}
.linear-filter-svg {
  clip-path: url(#clip-path-globe);
}
.global-content {
  fill: url(#linear-globe);
}
.airplane-global-track {
  stroke: $primary;
  stroke-width: 2px;
  stroke-dasharray: 5 7;
}
.airplane-global-track,
.airplane-track-svg {
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.airplane-track-svg {
  stroke: $yellow;
  stroke-width: 1.5px;
  stroke-dasharray: 0.5 5;
}

.stroke-yellow {
  stroke: $yellow;
}

.stroke-blue {
  stroke: $primary;
}

.fill-top-service {
  clip-path: url(#top-service);
}
.fill-bottom-service {
  clip-path: url(#bottom-service);
}
.fill-linear-service {
  fill: url(#linear-service);
}
.snack-bar-error {
  .MuiSnackbarContent-root {
    background: $errorColor;
    font-family: $font_lato_regular;
  }
}

.snack-bar-success {
  .MuiSnackbarContent-root {
    background: $successColor;
    font-family: $font_lato_regular;
  }
}

.MuiSnackbarContent-root {
  position: relative;
  .MuiSnackbarContent-message {
    padding-right: 15px;
  }
  .MuiSnackbarContent-action {
    position: absolute;
    top: 10px;
    right: 10px;
    margin-right: 0;
  }
}

p {
  margin: 0;
}

a.bordred-btn,
button.bordred-btn {
  font-size: 14px;
  font-family: $font_lato_regular;
  border: 1px solid $white;
  border-radius: 5px;
  color: $white;
  background: transparent;
  &:hover {
    background: $primary;
  }
}

.test-bloc {
  height: 600px;
}

.full-height {
  height: 100%;
}

.page-header {
  position: relative;
  min-height: 225px;
  background-color: $primary;
  .page-header-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: $primary;
    z-index: 1;
    opacity: 70%;
    left: 0;
  }
  .title-paths-container {
    padding-top: 60px;
    z-index: 2;
    margin: auto;
    text-align: center;
    .title-container {
      overflow: hidden;
      font-family: $font_lato_bold;
      color: $white;
      font-size: 42px;
      line-height: 54px;
      svg.full-svg-service-plus {
        height: 45px;
        max-width: 90%;
        .fill-service {
          fill: $white;
        }
      }
    }
  }
  &.mobile-page-header {
    .title-paths-container {
      padding-top: 30px;
      .title-container {
        font-size: 30px;
      }
    }
  }
  .header-paths {
    z-index: 2;
    color: $white;
    font-size: 12px;
    .path-title a {
      color: $white;
      text-decoration: none;
      &:hover {
        font-family: $font_lato_bold;
      }
    }
    .path-title-arrow {
      padding: 0 7px;
    }
  }
}

.slick-prev:before,
.slick-next:before {
  content: none !important;
}

.request-add-container {
  color: $descriptionGrey;
  font-size: 19px;
  text-align: center;
  padding: 50px 150px;
  position: relative;
  padding-bottom: 0 !important;
  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 50px 100px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 50px 20px;
  }
  svg {
    position: absolute;
    min-width: calc(100% + 150px);
    right: -150px;
    left: 0;
    height: auto;
    top: calc(100% - 200px);
  }
  h2.page_request_title {
    color: $primary;
    padding-bottom: 20px;
    font-size: 35px;
  }
  button.home-btn {
    background: $yellow;
    color: $primary;
    font-family: $font_lato_bold;
    min-height: 40px;
    width: fit-content;
    padding: 10px 20px;
    margin-top: 30px;
    &:hover {
      background: $primary;
      color: $white;
    }
  }
}

.form-content.custom-text-field {
  border: unset;
  background: $hoverColor;
  border-radius: 5px;
  position: relative;
  height: 77px;
  min-height: 77px;
  .icon-container {
    padding-left: 15px;
    align-items: center;
    display: flex;
    svg {
      fill: $grey;
      max-height: 20px;
    }
  }
  .input-container {
    align-items: center;
    padding: 0 15px;
    .MuiTextField-root {
      width: 100%;
    }
  }
  label.MuiInputLabel-formControl {
    transform: translate(0, 0) scale(1);
    color: $grey;
    font-size: 20px;
    font-family: $font_lato_regular;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: unset;
  }
  label.MuiInputLabel-formControl.MuiInputLabel-shrink {
    transform: translate(0, -15px) scale(0.6);
  }
  .MuiInputBase-root input,
  .MuiInputBase-root input[disabled],
  .MuiInputBase-root input:disabled {
    font-family: $font_lato_bold;
    font-size: 25px;
    opacity: 1;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-align: left;
    pointer-events: none;
    color: $primary;
  }
  .icon-container-open {
    transform: translate(0, -25px) scale(1);
  }
  .MuiInputBase-root input[disabled],
  .MuiInputBase-root input:disabled {
    color: $primary !important;
    -webkit-text-fill-color: $primary !important;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    content: none;
  }
}

/** Cookies consant block**/
.cookies-consent-container {
  background: $white !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 20px;
  .cookies-consent-content {
    width: 70%;
    flex: none !important;

    .svg-container {
      padding: 15px;
      svg {
        width: 100px;
        max-width: 100%;
      }
    }

    hr {
      background-color: $primary;
      height: 2px;
      width: 100px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    h3 {
      color: $primary;
      font-family: $font_lato_bold;
      font-size: 30px;
    }
    p {
      color: $descriptionGrey;
      a {
        color: $primary;
      }
    }
  }
  .cookies-consent-button-container {
    margin-top: auto;
    margin-bottom: auto;
  }
  .cookies-consent-button {
    min-width: 150px !important;
    padding: 15px 30px !important;
    background: $yellow !important;
    color: $primary !important;
    font-family: $font_lato_bold !important;
    font-size: 15px;
    border-radius: 5px !important;
    &:hover {
      background: $primary !important;
      color: $white !important;
    }
  }
  @media (max-width: 960px) {
    padding: 0;
    text-align: center;

    .cookies-consent-content {
      width: 100%;
      margin: 0 !important;
      .cookies-consent-info {
        padding: 0 10px;
        hr {
          margin: 10px auto;
        }
      }
    }
    .cookies-consent-button-container {
      width: 100%;
      padding: 0 10px;
      .cookies-consent-button {
        width: 100%;
        margin: 10px 0 !important;
      }
    }
  }
}

.date-selector-container {
  position: relative;
  .time-date-container {
    svg,
    input,
    input::placeholder {
      color: $white !important;
      opacity: 1;
    }
    svg {
      margin-right: 5px;
    }
  }
  .date-dropdown {
    transform: none !important;
    top: 25px !important;
    z-index: 99;
  }
}

.search {
  display: flex; 
  justify-content: center;
  align-items: center ;
  background: $white;
  width: 300px;
  border: 1px solid $primary;
  border-radius: 5px;
  height: 50px;
  .search-input {
    height: 100%;
    width: 100%;
    color: $primary;
    .MuiSvgIcon-root {
      color: $primary;
      font-size: 30px;
    }
    &::placeholder {
      color: $primary;
      opacity: 0.25;
      font-family: $font_lato_regular;
      font-size: 14px;
      text-transform: capitalize;
    }
    input {
      color: $primary;
      font-family: $font_lato_regular;
      font-size: 14px;
    }
    .MuiInputBase-root {
      height: 100%;
      padding: 0 20px;
      padding-right: 15px;
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    border-bottom: unset !important;
  }
}
