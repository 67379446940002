@import 'main.scss';
.origin-bloc-container {
  height: 100%;
}
.best-price-bloc-content {
  height: 100%;
  .promotion-bloc-container {
    height: 100%;
    .promotion-bloc-content {
      flex-grow: 1;
    }
  }
}
.promotion-bloc-container {
  cursor: pointer;
  border-radius: 5px;
  &:hover .share-btn-container {
    background: $primary;
    color: $white;
    .share-btn {
      .fill-blue {
        fill: $white;
      }
    }
  }
  &.promotion-bloc-container-hovered .share-btn-container {
    background: $primary;
    color: $white;
    .share-btn {
      .fill-blue {
        fill: $white;
      }
    }
  }
  .promotion-bloc-content {
    min-height: 170px;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $white;

    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .promotion-info {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: inset 0 0 0 2000px $overlayImage;
      z-index: 1;
      font-family: $font_lato_bold;
      padding: 20px;
      .to-container {
        font-size: 28px;
      }
      .from-container {
        font-size: 14px;
        margin-top: 7px;
      }
      .price-container {
        height: fit-content;
        background: $yellow;
        color: $primary;
        padding: 7px 4px 7px 9px;
        width: fit-content;
        max-width: 100%;
        border-radius: 2px;
        margin-top: 7px;
        .price-content {
          display: flex;
          font-size: 28px;
          .price-currency {
            font-size: 14px;
          }
        }
        .date-content {
          padding-left: 8px;
          font-family: $font_lato_regular;
          font-size: 14px;
          text-transform: uppercase;
        }
      }
    }
  }
  .share-btn-container {
    position: relative;
    padding: 13px 23px;
    color: $primary;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: 0px 3px 6px $transparentShadow;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .share-btn {
      position: absolute;
      right: 20px;
      button {
        padding: 0;
      }
    }
  }
  .origin-bloc-content {
    height: 100%;
    cursor: default;
    min-height: 170px;
    position: relative;
    border-radius: 10px;
    color: $primary;
    background: $backgroundWhiteLilac;
    .origin-info {
      height: 100%;
      border-radius: 5px;
      z-index: 1;
      font-family: $font_lato_bold;
      padding: 20px;
      text-align: start;
      .to-container {
        font-size: 24px;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
        margin-bottom: auto;
      }
      .from-container {
        font-family: $font_lato_regular;
        font-size: 14px;
        margin-top: 7px;
        min-height: 20px;
      }
      .bottom-container {
        margin-top: auto;
        .price-container {
          margin-top: 7px;
          height: fit-content;
          background: $yellow;
          color: $primary;
          padding: 7px 4px 7px 9px;
          width: fit-content;
          max-width: 100%;
          border-radius: 2px;
          .price-content {
            display: flex;
            font-size: 28px;
            border-radius: 2px;
            .price-currency {
              font-size: 14px;
            }
          }
        }
        .button-container {
          cursor: pointer;
          margin-top: 20px;
          border-radius: 5px;
          .link-title {
            margin-top: auto;
            width: 100%;
            background-color: $primary;
            color: $white;
            height: 50px;
            padding: 0 20px;
            font-family: $font_lato_bold;
            border-bottom: unset;
            span.MuiButton-label {
              text-decoration: unset !important;
            }
            &:hover {
              background-color: $yellow;
              color: $primary;
            }
          }
        }
      }
    }
  }
}
.promotion-list-item {
  width: 45%;
  &.promotion-list-single-item {
    flex-grow: 1;
  }
}

.share-popup {
  z-index: 10;
  top: 1px !important;
  .share-popup-container {
    background: transparent;
    padding-bottom: 17px;
    box-shadow: none;
    .share-popup-content {
      background: $primary;
      box-shadow: 0px 3px 6px $transparentShadow;
      border-radius: 20px;
      color: $white;
      font-size: 14px;
      font-family: $font_lato_regular;
      padding-left: 20px;
      padding-right: 10px;
      text-transform: capitalize;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: 38px;
        height: 38px;
        &:hover {
          background-color: $hoverBtnBack !important;
        }
      }
      .share-txt {
        padding-right: 9px;
      }
    }
  }
}
