@import 'main.scss';

.item-detail-container {
  padding: 40px 150px;

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 40px 100px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 40px 0.5%;
  }
  .no-item-container {
    padding: 100px 0;
    color: $primary;
  }
  .page-detail-title {
    color: $primary;
    font-size: 34px;
    font-family: $font_lato_bold;
    text-align: center;
    justify-content: center;
  }
  .page-detail-description {
    color: $descriptionGrey;
    font-size: 16px;
    text-align: center;
    max-width: 70%;
    padding-top: 30px;
    line-height: 24px;
    justify-content: center;
  }
}
.item-detail-container-mobile {
  padding: 40px 20px !important;
  .page-detail-title {
    text-align: left;
    justify-content: flex-start;
  }
  .page-detail-description {
    font-size: 14px;
    text-align: left;
    max-width: 100%;
    padding-top: 20px;
    line-height: 22px;
    justify-content: flex-start;
  }
}
.actuality-content-html {
  padding: 0px 150px 40px;

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 0px 100px 40px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 0px 20px 40px;
  }
}

.loader-html {
  margin: 100px 0;
}
