@import 'main.scss';

.menu-links {
  .link-title {
    font-family: $font_lato_bold;
    @include sizeSmall($smallFontSize);
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px 8px;
    padding-bottom: 0;
    &:hover {
      background: transparent;
    }
    @media only screen and (max-width: 980px) {
      padding: 6px;
      padding-bottom: 0;
    }
  }
}

.overlay-menu-layout {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  height: 100px;
}

.full-drop-down-container {
  padding: 20px 150px;
  color: $primary;
  .distination-dropdown-image-container {
    position: relative;
    min-height: 100%;
    div:first-of-type {
      border-radius: 5px;
    }
    .distination-dropdown-image {
      border-radius: 5px;
      transition-duration: 0.2s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .distination-dropdown-list-names {
    min-width: fit-content;
    padding-left: 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .MuiList-root {
      padding-top: 0;
    }
    .MuiListItem-button {
      padding: 0px 10px 0px 0px;
      max-width: 125px;
      margin-bottom: 10px;
      min-width: 125px;
      &:hover {
        background-color: transparent;
        .MuiListItemText-root .MuiTypography-root {
          font-family: $font_lato_bold;
          transform: translate(10px, 0px);
        }
      }
      .MuiListItemText-root {
        margin: 0;
        .MuiTypography-root {
          font-size: 14px;
          color: $primary;
          transition: 0.3s;
          font-family: $font_lato_regular;
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          text-align: left;
          min-width: 125px;
          max-width: 125px;
        }
      }
    }
    .distination-dropdown-more-btn {
      font-family: $font_lato_bold;
      color: $primary;
      font-size: 14px;
      position: relative;
      border-bottom: 2px solid $primary;
      border-radius: 0;
      padding: 0;
      width: fit-content;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .list-title {
    font-family: $font_lato_bold;
    font-size: 13px;
    color: $primary;
    margin-top: 20px;
  }

  .close-btn {
    position: absolute;
    right: 10%;
    color: $primary;
    svg {
      height: 20px;
      width: 20px;
      g {
        stroke-width: 0.1px;
      }
    }
  }
  .bloc-dropdown {
    padding: 15px;
    height: 100%;
    hr {
      width: 100%;
      background-color: $primary;
      margin-bottom: 15px;
      margin-top: 5px;
    }
    .drop-down-bloc-title-container {
      width: 100%;
      max-height: 57px;
    }
    .drop-down-centred-bloc {
      margin: auto;
      min-height: calc(100% - 60px);
      align-items: stretch;
      margin: auto;
    }
  }
  .divider-dropdown {
    margin: 15px;
  }
  .dropdown-footer-item {
    padding-left: 20px;
    color: $primary;
    button {
      color: $primary;
      text-transform: none;
      font-size: 14px;
      font-family: $font_lato_bold;
      svg {
        margin-right: 5px;
      }
    }
  }
}

.overlay-menu {
  height: 100vh;
  background-color: $backdropColor;
  margin-top: 20px;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay-menu-search {
  height: 100vh;
  margin-top: 20px;
  position: absolute;
  top: 0;
  width: 100%;
}

.drop-down-menu-icon.MuiSvgIcon-root {
  @include sizeSmall($mediumFontSize);
}

.reservation-dropdown-list {
  .reservation-options-list {
    .MuiListItem-button {
      overflow: hidden;
      padding: 5px;
      padding-right: 20px;
      align-items: baseline;
      padding-bottom: 10px;
      &:hover .MuiListItemText-root {
        transform: translate(10px, 0px);
        .MuiListItemText-primary {
          font-family: $font_lato_bold;
        }
      }
      .MuiListItemText-root {
        margin: 0;
        transition: 0.2s;
      }
      .MuiListItemText-primary {
        color: $primary;
        font-family: $font_lato_regular;
        font-size: 14px;
      }
      .MuiListItemIcon-root {
        min-width: unset;
        width: 18px;
        display: flex;
        justify-self: center;
        padding-right: 5px;
        justify-content: center;
        padding-right: 5px;
        svg {
          width: 14px;
          height: 11px;
          fill: $primary;
        }
        svg.svg-service-plus {
          width: 100%;
        }
        svg.air-planeboarding-icon {
          width: 20px;
          height: 13px;
        }
        svg.car-icon {
          width: auto;
          height: 14px;
          fill: $primary;
        }
        svg.checklist-icon {
          height: 13px;
          width: 11px;
        }
        svg.service-mini-icon {
          width: 17px;
        }
      }
    }
  }
  .right-side-list-container {
    padding: 12px;
    padding-top: 0;
  }
  .reservation-buttons-container {
    padding: 20px 20px 20px 20px;
  }
  .yellow-block {
    background: $yellow;
    border-radius: 5px;
    height: 100%;
    color: $primary;
    position: relative;
    overflow: hidden;
    .mini-logo-icon {
      width: auto;
      position: absolute;
      right: 0;
      opacity: 0.1;
      height: 109%;
      top: -5%;
    }
    .dropdown-reservation-description-container {
      display: flex;
      height: 100%;
      .dropdown-reservation-description-content {
        height: 100%;
        display: flex;
        padding: 20px 20px 20px 10px;
      }
      .dropdown-reservation-title {
        font-size: 28px;
        font-family: $font_lato_bold;
        padding-bottom: 10px;
      }
      .dropdown-reservation-description {
        font-size: 14px;
        font-family: $font_lato_bold;
      }
      .dropdown-reservation-description-img {
        display: flex;
        align-items: flex-end;
        height: 100%;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          inset: unset !important;
          bottom: 0 !important;
          height: auto !important;
          min-height: unset !important;
        }
      }
    }

    .logo-icon {
      width: 150px;
      path {
        fill: $primary;
      }
    }
    .left-side-container {
      height: 100%;
    }
    .blue-buttons-container {
      margin-top: auto;
      padding-top: 30px;
    }
  }
  .apple-button,
  .google-button {
    &:hover {
      box-shadow: 0px 5px 10px $transparentShadow;
      background: $white;
      color: $primary !important;
      svg {
        fill: $primary;
      }
    }
  }
}

.currency-item-txt.MuiMenuItem-root {
  color: $primary;
  font-family: $font_lato_bold;
  font-size: 14px;
}
.mobile-alpha {
  padding: 10px;
}
.mobile-menu {
  padding: 20px 0;
  padding-bottom: 50px;
  color: $primary;

  .download-app-btns {
    padding-top: 20px;
    .apple-button,
    .google-button {
      background: $black;
    }
    .google-button {
      .first-bloc-google {
        fill: url('#first-bloc-google-linear');
      }
      .second-bloc-google {
        fill: url('#second-bloc-google-linear');
      }
      .third-bloc-google {
        fill: url('#third-bloc-google-linear');
      }
      .fourth-bloc-google {
        fill: url('#fourth-bloc-google-linear');
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $primary;
  }
  .language-menu-selector {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  svg {
    fill: $primary;
  }
  input,
  .MuiInputBase-input {
    color: $primary;
  }

  .menu-footer {
    padding: 20px;
    .MuiListItem-root {
      color: $primary;
    }
  }
  .contact-btn-container {
    cursor: pointer;
    .contact-title {
      font-size: 18px;
      padding-bottom: 20px;
      cursor: pointer;
    }
  }

  .mobile-menu-header {
    padding: 20px 3px;
    .logo-icon {
      width: 100%;
      max-width: 200px;
      margin-right: 20px;
    }
  }

  .logo-blue {
    fill: $primary;
  }
  .logo-yellow {
    fill: $yellow;
  }
  .connection-container {
    padding: 30px 20px 20px 20px;
    .connection_divider {
      background-color: $primary;
      height: 20px;
    }
    .connection-text {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  .search-container {
    width: 100%;
    padding: 10px 20px 0 20px;
  }
  .collapsed-menu {
    padding-top: 30px;
    width: 100%;

    .MuiListItemIcon-root {
      min-width: 40px;
    }
    .collapsed-menu-item {
      border-top: 1px solid $lightPrimary;
      padding: 10px 4px;
      &:last-child {
        border-bottom: 1px solid $lightPrimary;
      }
    }
    .collapsed-menu-title span {
      font-size: 18px;
      font-family: $font_lato_bold;
      text-transform: uppercase;
    }
    .collapsed-sub-menu-title span {
      font-size: 18px;
    }
    .collapsed-sub-menu {
      padding-left: 30px;
      .menu-list-sub-mini-item {
        .collapsed-sub-menu-title span {
          font-size: 14px;
        }
        svg {
          width: 19px;
          min-height: 19px;
        }
        .MuiListItemIcon-root {
          min-width: 30px;
        }
      }
      svg {
        margin-right: 7px;
        width: 19px;
        height: 14px;
      }
      svg.air-planeboarding-icon {
        width: 29px;
        height: 18px;
      }
      svg.taxi-icon {
        height: 19px;
      }
      svg.checklist-icon {
        width: 21px;
        height: 18px;
      }
      svg.calender-icon {
        height: 16px;
        width: 15px;
      }
    }
  }
}

.select-dropdown {
  padding: 20px 15px;
  color: $primary;
  .select-dropdown-title {
    width: 100%;
    color: $primary;
    font-family: $font_lato_bold;
    hr {
      margin-top: 10px;
      background-color: $primary;
    }
  }
  &* {
    color: $primary;
  }
}
.select-value {
  svg {
    margin-right: 10px;
  }
  .currency-code {
    padding-right: 2px;
    font-size: 18px;
  }
}

.globe-dropdown-container {
  cursor: pointer;
  position: relative;
  .globel-airplane-svg,
  .globe-image {
    height: 100%;
    width: auto;
    max-height: 100%;
  }
  .globel-airplane-svg {
    position: absolute;
    height: 100%;
  }
  &:hover svg.globel-airplane-svg {
    animation: rotateReverse 1s linear 1 forwards;
  }
  &:hover svg.globe-image {
    .fill-yellow {
      fill: $primary;
    }
    .airplane-track-svg {
      stroke: $primary;
    }
    .global-content {
      fill: $yellow;
    }
  }
}

.reservation-title-bloc {
  hr {
    background-color: #004899;
    margin-top: 5px;
  }
}

@keyframes rotateReverse {
  to {
    transform: rotate(-290deg);
  }
}
