@import 'colors.scss';
@import 'fonts.scss';

.job-item-container {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'flex-start';
  align-items: 'flex-start';
  min-width: auto;
  min-height: 441px;
  flex-grow: 1;
  padding: 30px;
  margin: 20px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  .job-item-img {
    position: relative;
    height: 57px;
    img {
      min-width: auto !important;
      width: 57px !important;
      margin: 0 !important;
    }
  }
  .job-title {
    font-size: 21px;
    color: $primary;
    font-family: $font_lato_bold;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0px;
    height: 50px;
  }
  .job-ref-container {
    height: 50px;
    .job-ref {
      width: 100%;
      border: 1px solid $primary;
      border-radius: 5px;
      opacity: 1;
      margin-top: 15px;
      margin-bottom: 20px;
      .job-ref-text {
        font-size: 14px;
        color: $primary;
        font-family: $font_lato_regular;
        text-align: left;
        margin: 6px;
      }
    }
  }
  .job-date-container {
    height: 30px;
    .job-date {
      width: 100%;
      opacity: 1;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 1100px) and (min-width: 960px) {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 320px) {
        display: flex;
        flex-direction: column;
      }
      .job-date-text {
        font-size: 14px;
        color: $primary;
        font-family: $font_lato_regular;
        text-align: left;
        margin-right: 2px;
      }
    }
  }

  .job-description-container {
    margin-top: 10px;
    height: auto;
    height: 120px;
  }
  .job-description {
    color: $descriptionGrey;
    font-size: 16px;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0px;

    ul {
      margin-top: -20px;
    }
  }

  .job-action {
    background-color: $primary;
    color: $white;
    height: 40px;
    padding: 0 15px;
    font-family: $font_lato_regular;
    margin-top: 10px;
    width: 90%;
    margin-left: 5px;
    &:hover {
      background-color: $yellow;
      color: $primary;
    }
  }
}

hr.line {
  width: 58px;
  height: 2px;
  background-color: $primary;
  margin-top: 30px;
  margin-bottom: 30px;
}

.loader-candidature {
  margin-left: 10px;
  color: $white;
  height: 20px !important;
  width: 20px !important;
}

@media (max-width: 1280px) {
  .job-item-container {
    .job-action {
      background-color: $primary;
      color: $white;
      height: 40px;
      padding: 0 10px;
      font-family: $font_lato_bold;
      margin-top: 10px;
      width: 95%;
      &:hover {
        background-color: $yellow;
        color: $primary;
      }
    }
  }
}
