@import 'main.scss';

.footer-container {
  margin-top: 40px;
  position: relative;
  .footer-content {
    position: relative;
    color: $white;
    background: $primary;
    padding: 30px 5%;
    padding-bottom: 0;
    .footer-logo {
      padding-bottom: 20px;
    }
    .footer-list {
      width: 100%;
      display: flex;
      justify-content: space-around;
      .footer-list-container {
        flex-direction: column;
        align-items: flex-start;
        width: 16%;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
          width: auto;
          .social-media-container {
            padding-left: 0 !important;
          }
        }
        .MuiListItemText-root {
          flex: unset;
        }
        .footer-list-item-title {
          width: 100%;
          margin: 0;
          span {
            font-family: $font_lato_bold !important;
            font-size: 14px !important;
            text-transform: uppercase;
            padding: 0 8px;
          }
        }
        .footer-list-sub-item-title {
          width: 100%;
          margin: 0;
          span {
            font-size: 13px !important;
            padding: 0 8px;
          }
        }
      }
      .footer-list-contact {
        padding-left: 4px;
        .MuiListItem-root:first-child {
          padding-top: 0;
        }
        li.MuiListItem-root:first-child,
        nav.MuiList-root {
          padding-top: 0;
          li.MuiListItem-root {
            padding-left: 12px;
          }
          li.MuiListItem-root:last-child {
            padding-left: 0;
          }
        }
        .footer-social-media {
          button,
          a {
            min-width: 20px;
            min-height: 20px;
          }
        }
      }
    }
    .footer-list-mobile {
      width: 100%;
      .full-width-footer-list {
        width: 100%;
      }
      .MuiCollapse-root {
        padding-left: 25px;
      }
      .footer-list-item-title {
        width: 100%;
        span {
          font-family: $font_lato_bold !important;
          font-size: 14px !important;
          text-transform: uppercase;
        }
      }
      .footer-list-sub-item-title {
        width: 100%;
        span {
          font-size: 13px !important;
          padding: 0 8px;
        }
      }
      .footer-list-mobile-content {
        width: 100%;
      }
    }
  }
  .footer-info {
    background: $white;
    color: $primary;
    padding: 20px 5%;
    font-size: 10px;
    .footer-rights {
      font-size: 12px;
      font-family: $font_lato_bold;
    }
    .footer-legal-content-btn {
      font-size: 10px;
      color: $primary;
      text-transform: none;
      a {
        font-size: 10px;
        color: $primary;
        text-decoration: none;
      }
    }
  }
  .up-button {
    position: absolute;
    top: -20px;
    height: 40px;
    width: 40px;
    background-color: $white;
    color: $primary;
    &:hover {
      background: $yellow;
    }
  }
  .download-app-container {
    @media (max-width: 960px) {
      padding-left: 0px;
     
    }
    .apple-button,
    .google-button {
      background: $black;
      min-width: 137px;
      min-height: 45px;
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      svg {
        width: 30px;
      }
    }
    .google-button {
      .first-bloc-google {
        fill: url('#first-bloc-google-linear');
      }
      .second-bloc-google {
        fill: url('#second-bloc-google-linear');
      }
      .third-bloc-google {
        fill: url('#third-bloc-google-linear');
      }
      .fourth-bloc-google {
        fill: url('#fourth-bloc-google-linear');
      }
    }
    .apple-button,
    .google-button {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
}
