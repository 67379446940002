@import 'main.scss';

.notfound-page-container {
  padding-top: 200px;
  color: $primary;
  .background-map {
    position: absolute;
    z-index: -1;
  }
  .notfound-img {
    margin-left: -14%;
    max-width: 86%;
    height: auto;
    min-width: 250px;
  }
  .notfound-page-cntent {
    position: relative;
    z-index: 0;
    padding: 0 20px;
    padding-bottom: 50px;
    img {
      max-height: 500px !important;
      width: auto !important;
      min-width: unset !important;
      inset: unset !important;
      right: 0 !important;
      left: unset !important;
    }
    .notfound-title {
      font-family: $font_lato_bold;
      font-size: 34px;
      padding-top: 40px;
    }
    .notfound-sub-title {
      font-size: 14px;
      padding-top: 30px;
    }
    .notfound-sub-btn {
      min-width: 260px;
      color: $primary;
      background: $yellow;
      font-size: 14px;
      font-family: $font_lato_bold;
      margin-top: 20px;
      &:hover {
        box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
      }
    }
    @media (max-width: 1400px) and (min-width: 1300px) {
      .notfound-content {
        padding-left: 100px;
        min-height: 300px;
      }
    }

    @media (max-width: 1300px) and (min-width: 800px) {
      .notfound-content {
        padding-left: 20px;
        padding-top: 10px;
        min-height: 300px;
      }
    }

    @media (max-width: 800px) {
      .notfound-content {
        padding-left: 20px;
        max-width: 55%;
        padding-top: 10px;
      }
    }
  }
}
