@import 'main.scss';
.time-line-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .time-line-title {
    color: $primary;
    font-size: 28px;
    font-family: $font_lato_bold;
  }
  .gallery-slider {
    height: 130px;
    padding: 0 30px;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    .slick-slider {
      .slick-track {
        min-height: 150px;
      }
      .gallery-slider-item {
        height: 100%;
        margin-left: 0;
        width: 100px !important;
        padding: 5px 15px;
        box-shadow: 0px 3px 6px $transparentShadow;
        background: $white;
        color: $primary;
        font-family: $font_lato_bold;
        border: 1px solid $primary;
        &.selected {
          background: $yellow;
          color: $primary;
        }
      }
    }
    .slick-next-arrow {
      right: -30px;
    }
    .slick-prev-arrow {
      left: -30px;
    }
    .slick-next-arrow,
    .slick-prev-arrow {
      bottom: 39px;
    }
  }
  .media-info-container {
    position: relative;
    box-shadow: 0px 3px 6px $transparentShadow;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;
    .media-info-img {
      height: 300px;
      position: relative;
      * {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      img {
        transition-duration: 0.2s;
      }
      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
    .media-info-content {
      padding: 20px 25px;
      .media-info-title {
        color: $primary;
        font-family: $font_lato_bold;
        font-size: 25px;
      }
      .media-info-description {
        padding-top: 10px;
        color: $descriptionGrey;
      }
      .media-info-btn {
        padding-top: 20px;
        button {
          background: $primary;
          color: $white;
          font-family: $font_lato_bold;
          padding: 10px 25px;
          min-width: 170px;
          &:hover {
            background: $yellow;
            color: $primary;
          }
        }
      }
    }
  }
  .bloc-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 480px;
 
    @media only screen and (min-width: 1600px) {
      width: 85%;
    }
    .time-line-bloc {
      display: 'flex';
      flex-direction: 'row';
      justify-content: 'flex-start';
      align-items: 'flex-start';
      min-width: auto;
      min-height: 400px;
      flex-grow: 1;
      margin: 20px !important;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0px;
      @media only screen and (min-width: 1300px) {
        min-height: 480px;
      }
      .slider-container {
        display: flex;
        flex-direction: column;
        margin: 20px;
        .time-line-title {
          color: $primary;
          font-size: 28px;
          font-family: $font_lato_bold;
          margin-bottom: 5px;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          
        }
      }
      .btn-up-style {
        transform: rotate(-90deg);
        margin-left: 40px;
        margin-bottom: 10px;
        height: 20px;
        width: 20px;
        color: $primary;
      }
      .btn-up-disabled-style {
        transform: rotate(-90deg);
        margin-left: 40px;
        margin-bottom: 20px;
        height: 20px;
        width: 20px;
        color: $smallLink;
        pointer-events: none;
      }

      .btn-down-style {
        transform: rotate(90deg);
        margin-left: 40px;
        margin-top: 10px;
        height: 20px;
        width: 20px;
        color: $primary;
      }
      .btn-down-disabled-style {
        transform: rotate(90deg);
        margin-left: 40px;
        margin-top: 10px;
        height: 20px;
        width: 20px;
        color: $smallLink;
        pointer-events: none;
      }

      .media-info-img {
        height: 250px;
        position: relative;
        @media only screen and (min-width: 1300px) {
          height: 300px;
        }
        * {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        img {
          transition-duration: 0.2s;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
      .media-info-content {
        padding: 20px 25px;
        .media-info-title {
          color: $primary;
          font-family: $font_lato_bold;
          font-size: 25px;
        }
        .media-info-description {
          padding-top: 10px;
          color: $descriptionGrey;
          -webkit-line-clamp: 3;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          min-height: 70px;
        }
        .media-info-btn {
          padding-top: 10px;
          @media only screen and (max-width: 950px) {
            padding-top: 30px
          }
          button {
            background: $primary;
            color: $white;
            font-family: $font_lato_bold;
            padding: 10px 25px;
            min-width: 170px;
            &:hover {
              background: $yellow;
              color: $primary;
            }
          }
        }
      }

      .gallery-slider-item {
        height: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100px !important;
        padding: 5px 15px;
        box-shadow: 0px 3px 6px $transparentShadow;
        background: $white;
        color: $primary;
        font-family: $font_lato_bold;
        border: 1px solid $primary;
        &.selected {
          background: $yellow;
          color: $primary;
        }
      }

      .gallery-slider-item-without-height {
        //height: 100%;
        margin-left: 0;
        margin-bottom: 10px;
        width: 100px !important;
        padding: 5px 15px;
        box-shadow: 0px 3px 6px $transparentShadow;
        background: $white;
        color: $primary;
        font-family: $font_lato_bold;
        border: 1px solid $primary;
        &.selected {
          background: $yellow;
          color: $primary;
        }
      }
    }
  }
}
