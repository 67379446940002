@import 'main.scss';

.network-container {
  .network-newsletter-container {
    margin-top: 60px;
  }
}
.box-map-container {
  max-height: 550px;
  min-height: 550px;
  position: relative;

  div:first-child {
    max-height: 100%;
  }

  .mapboxgl-ctrl-fullscreen {
    width: 29px;
    height: 29px;
    background: transparent;
    z-index: 2;
    position: absolute;
    right: 20px;
    top: 20px;
    min-width: unset;
    padding: 0;
    transition: all 0.2s ease-in-out;
    svg {
      height: 20px;
      width: 20px;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .country-bloc-header {
    display: none;
  }

  .overlays {
    display: flex;
    justify-content: center;
    .zoom-control {
      z-index: 1;
    }
  }
  .map-control-bloc {
    position: absolute;
    background: transparent;
    margin-left: 50px;
    max-width: 300px;
    width: auto;
    left: 0;

    .map-control-paper {
      width: 100%;
      padding: 20px;
      box-shadow: 0px 0px 15px $transparentShadow;
      border-radius: 5px;
      .map-details-content {
        height: 100%;
      }
    }
    .map-control-title {
      font-size: 23px;
      display: flex;
      align-items: baseline;
      color: $primary;
      font-family: $font_lato_bold;
      flex-direction: column;
      hr {
        width: 42px;
        background: $primary;
        height: 2px;
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
    .map-control-content {
      display: flex;
      align-items: center;
      .map-control-footer {
        padding-top: 20px;
        button.flights-search-btn.MuiButton-root {
          min-width: 135px;
          height: 40px;
          width: 100%;

          &.Mui-disabled {
            opacity: 0.5;
          }
        }
        .map-control-footer-btn {
          width: 100%;
          padding-top: 10px;
        }
        .map-control-info {
          color: $primary;
          font-size: 14px;
          display: flex;
          align-items: center;
          padding-right: 10px;
          span.map-line {
            height: 2px;
            width: 50px;
            display: block;
            border-bottom: 2px solid $primary;
            margin-right: 5px;
            &.dashed {
              border-bottom-style: dashed;
            }
          }
        }
      }
    }
    .form-container {
      .form-container-reservation-container {
        max-width: 352px;
        padding: 10px 0;
        .form-content {
          min-height: 77px;
          height: 77px;
          .combo-box-date-picker {
            padding: 0 15px;
            label.MuiInputLabel-formControl.MuiInputLabel-shrink {
              transform: translate(0, -15px) scale(0.6);
            }
          }
        }
        .icon-container-open {
          transform: translate(0, -25px) scale(1);
        }
      }
    }
  }
  .box-map-loader {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: transparent;
    .MuiCircularProgress-colorPrimary {
      color: $primary;
      min-height: 40px;
      min-width: 40px;
    }
  }

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .circle-marker {
    background: $yellow;
    border-radius: 100%;
    padding: 10px;
  }
  .pin-marker-icon {
    cursor: pointer;
    circle {
      fill: $yellow;
      z-index: 3;
    }
  }
  .map-pin-icon {
    cursor: pointer;
  }
  .animated-dot {
    .pin-marker-icon circle {
      stroke: $primaryWithOpacity;
      stroke-width: 10;
      animation: strokePulse 0.7s infinite alternate;
      fill: $primary;
    }
  }
  .zoom-control {
    .mapboxgl-ctrl {
      background: transparent;
      box-shadow: none !important;

      .mapboxgl-ctrl-zoom-in,
      .mapboxgl-ctrl-zoom-out {
        background-color: $primary;
        border-radius: 5px;
        width: 20px;
        height: 20px;
      }
      .mapboxgl-ctrl-zoom-in {
        margin-bottom: 5px;
        .mapboxgl-ctrl-icon {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E") !important;
        }
      }
      .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E") !important;
      }
    }
  }
  &.box-map-mobile {
    min-height: 675px;
    max-height: 675px;
    .mapboxgl-ctrl-fullscreen {
      background: white;
      border-radius: 100%;
      padding: 10px;
      width: 35px;
      height: 35px;
      box-shadow: 0px 0px 15px $transparentShadow;
      svg {
        transform: unset !important;
      }
    }
    .zoom-control {
      .mapboxgl-ctrl {
        background: transparent;
        box-shadow: none !important;

        .mapboxgl-ctrl-zoom-in,
        .mapboxgl-ctrl-zoom-out {
          box-shadow: 0px 0px 15px $transparentShadow;
          background: white;
          border-radius: 100%;
          padding: 10px;
          width: 35px;
          height: 35px;
        }
        .mapboxgl-ctrl-zoom-in {
          margin-bottom: 5px;
          .mapboxgl-ctrl-icon {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23004899'%3E %3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E %3C/svg%3E") !important;
          }
        }
        .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='20' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23004899'%3E %3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E %3C/svg%3E") !important;
        }
      }
    }
    .map-control-bloc {
      margin: 0 !important;
      bottom: 0;
      left: unset;
      min-height: unset;
      max-width: unset;
      .map-control-paper {
        border-radius: 0;
        padding: 10px 20px;
        margin-bottom: 10px;
        .map-control-title {
          width: 100%;
          max-width: 100%;
          text-align: center;
          justify-content: center;
          padding-bottom: 12px;
          min-width: unset;
        }
        .map-control-content {
          flex-wrap: wrap;
          .flight-manage-bloc-container {
            flex-wrap: wrap;
            .departure-container {
              width: 100%;
              .form-container-reservation-container {
                width: 100%;
                max-width: 100%;
                .form-content {
                  height: 64px;
                  min-height: 64px;
                  label.MuiInputLabel-formControl,
                  .MuiInputBase-root input {
                    font-size: 20px;
                  }
                  label.MuiInputLabel-formControl.MuiInputLabel-shrink {
                    transform: translate(0, -10px) scale(0.6);
                  }
                  p.MuiFormHelperText-root {
                    font-size: 10px;
                  }
                  .icon-container-open {
                    transform: translate(0, -18px) scale(1);
                  }
                }
              }
            }
          }
          .map-control-footer {
            padding: 5px;
            .map-control-footer-btn {
              width: 100%;
            }
            .flights-search-btn {
              width: 100%;
              max-width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    &.full-screen-map {
      .map-control-bloc {
        position: fixed;
        .map-control-paper {
          margin: 0;
          padding: 10px;
          .map-control-footer {
            padding: 5px;
            .flights-search-btn {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &.full-screen-map {
    position: fixed;
    height: 100vh;
    z-index: 1298;
    top: 0;
    max-height: unset;
    .form-container .form-container-reservation-container .form-content .departure-country-dropdown {
      top: unset !important;
      bottom: calc(100% + 7px) !important;
    }
    .map-control-bloc .map-control-paper {
      margin-bottom: 0;
    }
    .airports-list {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-radius: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .mapboxgl-ctrl-fullscreen {
      svg {
        transform: scale(1.2);
      }
      &:hover {
        svg {
          transform: scale(1);
        }
      }
    }
  }
}
@keyframes strokePulse {
  0% {
    stroke: transparent;
    stroke-width: 0;
  }
  100% {
    stroke: $primaryWithOpacity;
    stroke-width: 10;
  }
}
