@import 'main.scss';

.newsletter-container {
  border: 1px solid $primary;
  padding: 40px 20%;
  text-align: center;
  .MuiFormHelperText-root {
    color: $errorColor;
    margin-top: 10px;
  }
  .newsletter-title {
    font-size: 34px;
    font-family: $font_lato_bold;
    color: $primary;
  }
  .newsletter-description {
    font-size: 14px;
    color: $descriptionGrey;
    padding: 20px 0;
  }
  .newsletter-form {
    border: 1px solid $primary;
    border-radius: 5px;
    min-height: 40px;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: center;

    &.form-error {
      border-color: $errorColor;
    }
    .newsletter-btn {
      background: $primary !important;
      color: $white;
      font-family: $font_lato_bold;
      font-size: 14px;
      min-height: 40px;
      border-radius: 0;
      min-width: fit-content;
      padding: 0 20px;
      .loader-newsletter {
        margin-left: 10px;
        color: $white;
        height: 20px !important;
        width: 20px !important;
      }
    }
    .MuiInputBase-root {
      min-height: 40px;
      width: 100%;
      padding: 0 20px;
      input::placeholder {
        font-size: 14px;
        color: $primary;
        opacity: 0.25;
        font-family: $font_lato_regular;
      }
    }
  }
  &.mobile-newsletter {
    padding: 40px 10%;
    .newsletter-form {
      border: unset;
      flex-wrap: wrap;
      &.form-error .MuiInputBase-root {
        border-color: $errorColor;
      }
      .newsletter-btn {
        border-radius: 5px;
        width: 100%;
        margin-top: 20px;
      }
      .MuiInputBase-root {
        border-radius: 5px;
        border: 1px solid $primary;
      }
    }
  }
}

.grecaptcha-badge {
  opacity: 0;
}

.hide-captcha {
  display: none;
}
