@import 'colors.scss';
@import 'fonts.scss';
.MuiSelect-nativeInput,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: unset;
}
.application-select {
  z-index: 2 !important;
  position: absolute !important;
  max-height: 300px;
  .MuiMenu-paper {
    background: $primary;
    color: $white;
    box-shadow: unset;
    margin-top: -3px;
    max-height: 300px;
    &.MuiPaper-rounded {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .MuiListItem-root {
      padding: 10px 20px;
      height: fit-content;
      font-size: 14px;
      font-family: $font_lato_regular;
      &.Mui-selected {
        background: $yellow !important;
      }
    }
  }
}

.candidature-form-container {
  .candidature-bloc-container {
    margin-top: 20px;
    flex-direction: 'row';
    justify-content: 'center';
  }

  .select-ref-candidature {
    width: 100%;
    height: 50px;
    background-color: $primary;
    border-radius: 5px 5px 0px 0px;
    font-size: 16px;
    .MuiSelect-selectMenu {
      color: $white;
      font-family: $font_lato_regular;
      font-size: 16px;
      padding-left: 20px;
    }
    svg {
      color: $white;
    }
  }

  select:focus {
    outline: none;
  }

  .select-ref-text {
    height: 14px;
    color: $white;
    text-align: left;
    font-family: $font_lato_regular;
    letter-spacing: 0px;
  }
  .input-container-left {
    flex-wrap: 'nowrap';
    padding-left: 15px;
  }
  .input-container-right {
    flex-wrap: 'nowrap';
    padding-right: 15px;
  }

  .input {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 1px solid $primary;
    border-radius: 5px;
    text-align: left;
    font-family: $font_lato_regular;
    letter-spacing: 0px;
    color: $primary;
    padding-left: 10px;
    padding-right: 10px;
  }

  .input-error {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: 1px solid $errorColor;
    border-radius: 5px;
    text-align: left;
    font-family: $font_lato_regular;
    letter-spacing: 0px;
    color: $primary;
    padding-left: 10px;
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    color: $primary;
    font-family: $font_lato_regular;
  }
  .situation-text {
    color: $primary;
    font-family: $font_lato_regular;
    font-size: 16px;
  }

  .status-container {
    span {
      color: $primary;
    }
  }

  .margin-20 {
    margin-top: 20px !important;
  }

  .input-file {
    display: none !important;
  }

  .custom-input-file {
    width: 100%;
    height: 50px;
    background: $primary 0% 0% no-repeat padding-box;
    border: 1px solid $lighterPrimary;
    border-radius: 5px;
  }

  .custom-input-text {
    width: auto;
    height: auto;
    text-align: left;
    font-family: $font_lato_regular;
    letter-spacing: 0px;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    color: $white;
    font-size: 16px;
    flex-wrap: nowrap;
    padding-left: 10px;
    padding-right: 00px;
  }

  .padding-12 {
    padding: 12px;
  }
  .left-position {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    .job-btn {
      width: 100%;
      padding-top: 30px;
      margin-top: auto;
      button {
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        z-index: 1;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
        width: 135px;
        max-width: 100%;
        height: 40px;
        background-color: $yellow;
        color: $primary;
        font-family: $font_lato_bold;
        font-size: 14px;
        span.bg {
          background: $primary;
          width: 0;
          left: 0;
          height: 100%;
          position: absolute;
          opacity: 0;
          z-index: -1;
          transition: all 0.5s ease-in-out;
          border-radius: 5px;
          top: 0;
        }
        &:hover {
          color: $white;
          span.bg {
            transition: all 0.5s ease-in-out;
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }

  .icon-style {
    padding: 12px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    position: relative;
    img {
      width: 20px !important;
      min-width: auto !important;
      height: 20px !important;
      min-height: auto !important;
    }
  }
  .center-position {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .column-orientation {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .recaptcha-style {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px !important;
  }
  .button-container {
    width: 136px;
    height: 40px;
    background: $yellow 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-top: 20px;
    border: none;
    cursor: pointer;
  }

  .button-text {
    width: 121px;
    height: 13px;
    text-align: center;
    font-family: $font_lato_regular;
    letter-spacing: 0px;
    color: $primary;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .text-error {
    padding-left: 10px;
    font-family: $font_lato_regular;
    color: $errorColor !important;
  }

  @media screen and (max-width: 960px) {
    .input-container-left {
      padding-left: 0px;
      padding-top: 5px;
    }
    .input-container-right {
      padding-right: 0px;
      padding-top: 5px;
    }
  }
}
