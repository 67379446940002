@import 'main.scss';

.corporate-home-container {
  padding: 50px 250px;
  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 50px 200px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 50px 20px;
  }
  .corporate-bloc-container {
    min-height: 320px;
    margin: 10px 0;
    .corporate-bloc-info {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px 3px 6px $transparentShadow;
      padding: 20px;
      height: 100%;
      .corporate-bloc-title {
        color: $primary;
        font-family: $font_lato_regular;
        padding-bottom: 15px;
      }
      .corporate-bloc-description {
        padding-bottom: 15px;
        color: $descriptionGrey;
      }
    }
    .corporate-bloc-img {
      position: relative;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      img {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .corporate-bloc-btn {
      margin-top: auto;

      button {
        background-color: $primary;
        color: white;
        text-transform: none;
        font-family: $font_lato_regular;
        padding: 5px 10px;
        &:hover {
          background-color: $yellow;
        }
      }
    }
  }
  .corporate-slider {
    margin-bottom: 50px;
    height: 400px;
    .slick-arrow {
      position: absolute;
      height: 50px;
      width: 50px;
      top: 50%;
      padding: 0;
      z-index: 1;
      svg {
        font-size: 50px;
        color: $yellow;
      }
      &.slick-next-arrow {
        right: 20px !important;
      }
      &.slick-prev-arrow {
        left: 20px !important;
      }
      &.slick-disabled {
        opacity: 0.25;
      }
    }

    .gallery-slider-item {
      position: relative;
      height: 400px;
    }
    .slick-slider {
      min-width: 100%;
      .slick-list,
      .slick-track {
        height: 100%;
      }
    }
    .slick-dots {
      bottom: 10px;
      li {
        width: fit-content;
        height: fit-content;
      }
      .slider-page {
        .slider-dot {
          width: 9px;
          height: 9px;
          border-radius: 9px;
          display: block;
          border: 1px solid $yellow;
        }
      }
      .slick-active .slider-page .slider-dot {
        background-color: $yellow;
      }
    }
  }
  .corporate-bloc-container-mobile {
    min-height: 400px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    .corporate-bloc-info {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px 3px 6px $transparentShadow;
      padding: 20px;
      height: 100%;
      .corporate-bloc-title {
        color: $primary;
        font-family: $font_lato_regular;
        padding-bottom: 15px;
      }
      .corporate-bloc-description {
        padding-bottom: 15px;
        color: $descriptionGrey;
      }
    }
    .corporate-bloc-img {
      position: relative;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      min-height: 200px;
      img {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    .corporate-bloc-btn {
      margin-top: auto;

      button {
        background-color: $primary;
        color: white;
        text-transform: none;
        font-family: $font_lato_regular;
        padding: 5px 10px;
        &:hover {
          background-color: $yellow;
        }
      }
    }
  }
}


.home-actualities-container-mobile {
  .actualities-content .home-actuality-container-1 {
    .home-actuality-bloc-1 {
      .home-actuality-container {
        flex-direction: column !important;
        .home-actuality-info-container {
          border-top-right-radius: 0px !important;
          border-bottom-left-radius: 10px !important;
        }
      }
    }
  }
  .home-promotion-btn-container button,
  .home-promotion-btn-container a {
    width: 100%;
  }
  .promotion-item {
    padding: 10px;
  }
  .promotion-container-footer {
    padding-right: 0px !important;
  }
  .home-actualities-container .promotion-actuality-footer-link,
  .home-actualities-container .promotion-actuality-footer {
    width: 100%;
   // display: none;
  }

  .home-actualities-container .promotion-actuality-footer-link-hidden,
  .home-actualities-container .promotion-actuality-footer-hidden {
    width: 100%;
    display: none;
  }

  .promotion-actuality-container {
    padding-left: 5px !important;
    padding-right: 20px !important;
    &.promotion-actuality-container-one-mobile {
      padding-right: 0px !important;
    }
  }
  .home-actualities-header {
    padding-bottom: 10px !important;
  }
  .actualities-content {
    margin-left: 0 !important;
    padding-right: 15% !important;
    &.actualities-content-one {
      padding-right: 0 !important;
    }
  }
  .actualities-slider-btns {
    display: none;
  }
  .home-actuality-container {
    flex-direction: column;
    .home-actuality-img-container {
      width: 100% !important;
      height: 60% !important;
      min-height: 174px !important;
      img {
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 10px !important;
      }
    }
    .home-actuality-info-container {
      width: 100% !important;
      flex-grow: 1;
    }
  }
  .description-bloc {
    padding: '20px 50px';
    color: '#707070';
    font-size: 16;
    text-align: 'center';
  }
}

.home-actualities-container {
  padding: 50px 150px;
  padding-right: 0;

  .home-actuality-img-container {
    img {
      background-color: $imagesPlaceHolderColor;
    }
  }

  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 50px 100px;
    padding-right: 0;
    .home-actualities-header {
      padding-right: 100px !important;
    }
  }

  @media only screen and (max-width: 1300px) {
    padding: 10px 20px;
    padding-right: 0;
    .home-actualities-header {
      padding-right: 20px !important;
    }
  }

  .promotion-bloc-container {
    .share-btn-container {
      font-family: $font_lato_bold;
      .share-btn-txt {
        min-width: 90%;
      }
    }
    .promotion-bloc-content {
      .promotion-info {
        .from-container {
          font-family: $font_lato_regular;
        }
      }
    }
  }
  .link-title {
    color: $primary;
    font-family: $font_lato_bold;
    font-size: 14px;
    border-radius: inherit;
    padding: 0;
    &:hover {
      background: none;
    }
    span.MuiButton-label {
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  }

  .home-actualities-header {
    padding-right: 150px;
    padding-bottom: 30px;
    .actualities-slider-btns {
      button {
        color: $primary;
        height: 30px;
        width: 30px;
        padding: 5px;
        svg {
          height: 35px;
          width: 35px;
        }
      }
      button.MuiIconButton-root.Mui-disabled {
        opacity: 0.25;
      }
    }
  }
  .promotion-actuality-footer-link {
    padding-top: 20px;
  }
  .promotion-actuality-footer {
    padding-top: 5px;
    .embla__dots {
      display: flex;
      align-items: center;
    }
    .dot-button {
      background: $primary;
      padding: 0;
      margin: 5px;
      width: 5px;
      height: 5px;
      opacity: 0.25;
    }
    .dot-button.selected {
      width: 10px;
      height: 10px;
      opacity: 1;
    }
  }

  .promotion-actuality-container {
    padding: 10px 0;
    &.promotion-actuality-container-one {
      .home-promotion-container {
        flex-direction: row;
        min-height: 282px;
        .home-promotion-img-container {
          width: 50%;
          min-height: 282px;
          height: 100%;
          img {
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0;
          }
        }
        .home-promotion-info-container {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }

  .home-actualities-title {
    font-family: $font_lato_bold;
    font-size: 34px;
    color: $primary;
    overflow: hidden;
    svg.full-svg-service-plus {
      height: 45px;
      width: auto;
      max-width: 100%;
    }
  }
  .actualities-content {
    height: 100%;
    overflow: hidden;
    padding-right: 150px;
    .promotion-item {
      padding: 10px 3% 10px 8px;
    }
    .promotion-slider-item {
      padding: 10px;
      padding-left: 8px;
      .home-promotion-info-container {
        text-align: left;
        display: flex;
        flex-direction: column;
      }
    }
    .embla__slide {
      opacity: 0.25;
    }
    .embla__slide.is-selected {
      opacity: 1;
    }
    .embla,
    .embla__viewport,
    .embla__container {
      overflow: visible;
      height: 100%;
      width: 100%;
    }
    .embla__slide__inner {
      height: 100%;
    }
    .home-actuality-container-4,
    .home-actuality-container-3 {
      .home-actuality-bloc-0 {
        width: 100%;
        .home-actuality-img-container {
          height: 450px;
        }
        .home-actuality-container {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .home-actuality-container-3,
    .home-actuality-container-4 {
      height: 100%;
      padding: 10px;
      .home-actuality-bloc-1,
      .home-actuality-bloc-2 {
        width: calc(50% - 10px);
        .home-actuality-container {
          display: flex;
          flex-direction: column;
        }
        .home-actuality-description-container {
          -webkit-line-clamp: 2 !important;
        }
      }
      .home-actuality-bloc-3 {
        width: 100%;
        padding-top: 40px;
        .home-actuality-container {
          display: flex;
          .home-actuality-img-container {
            height: 100%;
            width: 50%;
            min-height: 282px;
            img {
              border-top-right-radius: 0;
              border-bottom-left-radius: 10px;
            }
          }
          .home-actuality-info-container {
            width: 50%;
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }
        .home-actuality-description-container {
          -webkit-line-clamp: 6 !important;
        }
      }
      .home-actuality-bloc-1 {
        padding-right: 20px;
      }
    }
    .home-actuality-container-3.home-actuality-container-special {
      flex-direction: column;
      .home-actuality-bloc-1,
      .home-actuality-bloc-2 {
        width: 100%;
        height: calc(50% - 10px);
        .home-actuality-container {
          flex-direction: row;
          .home-actuality-info-container {
            height: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            width: 50%;
            .home-actuality-info-content {
              padding: 20px;
              min-height: 168px;
            }
          }
          .home-actuality-img-container {
            height: 100%;
            width: 50%;
            min-height: 282px;
            img {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              border-top-right-radius: 0;
              background-color: $imagesPlaceHolderColor;
            }
          }
        }
      }
      .home-actuality-bloc-2 {
        padding-top: 20px;
      }
    }
    .home-actuality-container-2 {
      padding: 10px;
      height: 100%;
      .home-actuality-bloc-1,
      .home-actuality-bloc-2 {
        width: 100%;
        .home-actuality-description-container {
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        .home-actuality-container {
          display: flex;
          .home-actuality-img-container {
            height: 100%;
            width: 50%;
            min-height: 282px;
            img {
              border-top-right-radius: 0;
              border-bottom-left-radius: 10px;
            }
          }
          .home-actuality-info-container {
            width: 50%;
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }
      }
      .home-actuality-bloc-2 {
        padding-top: 40px;
      }
    }
    .home-actuality-container-1 {
      padding: 10px;
      height: 100%;
      .home-actuality-bloc-1 {
        width: 100%;
        .home-actuality-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          .home-actuality-img-container {
            height: 100%;
            width: 50%;
            min-height: 282px;
            img {
              border-top-right-radius: 0;
              border-bottom-left-radius: 10px;
            }
          }
          .home-actuality-info-container {
            width: 50%;
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }
      }
    }
    .home-actuality-container {
      min-height: 174px;
      position: relative;
      width: 100%;
      border-radius: 10px;
      height: 100%;
      &.MuiPaper-root {
        box-shadow: none;
      }
      .home-actuality-info-container {
        box-shadow: 0px 3px 6px $transparentShadow;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        flex-grow: 1;
        align-items: baseline;
        min-height: fit-content;
      }
      .home-actuality-img-container {
        height: 174px;
        position: relative;
        width: 100%;
        img {
          height: 100%;
          width: 100%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
      .home-actuality-info-content {
        padding: 20px;
        min-height: 168px;
        height: 100%;
        .actuality-details-button {
          margin-top: auto;
        }
        .home-actuality-title-container {
          font-family: $font_lato_bold;
          font-size: 21px;
          color: $primary;
          padding-bottom: 20px;
          text-align: left;
        }
        .home-actuality-description-container {
          color: $grey;
          font-size: 16px;
          margin-bottom: 20px;
          text-align: left;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
        .home-actuality-btn-container {
          button {
            padding-right: 25px;
            min-height: 50px;
            padding-left: 25px;
            width: 165px;
            max-width: 100%;
            color: $white;
            background: $primary;
            font-size: 14px;
            span {
              font-family: $font_lato_bold;
            }
          }
        }
      }
    }
  }
  .home-promotion-container {
    min-height: 450px;
    position: relative;
    width: 100%;
    border-radius: 10px;
    height: 100%;
    box-shadow: unset;
    display: flex;
    flex-direction: column;
    .home-promotion-img-container {
      height: 450px;
      position: relative;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: $imagesPlaceHolderColor;
      }
    }
    .home-promotion-info-container {
      padding: 20px;
      box-shadow: 0px 3px 6px $transparentShadow;
      flex-grow: 1;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      flex-direction: column;
      .home-promotion-title-container {
        font-family: $font_lato_bold;
        font-size: 21px;
        color: $primary;
        padding-bottom: 20px;
      }
      .home-promotion-description-container {
        color: $grey;
        font-size: 16px;
        margin-bottom: 20px;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
      .home-promotion-btn-container {
        margin-top: auto;
        button,
        a {
          padding-right: 25px;
          min-height: 50px;
          padding-left: 25px;
          min-width: 165px;
          max-width: 100%;
          color: $white;
          background: $primary;
          font-size: 14px;
          span {
            font-family: $font_lato_bold;
          }
        }
      }
    }
  }
}
.home-actualities-container.home-actualities-container-padding {
  padding: 50px 150px;
  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 50px 100px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 50px 20px;
  }
}
.home-service-slider-container {
  .home-actualities-container {
    padding: 20px 150px;

    @media only screen and (max-width: 1500px) and (min-width: 1300px) {
      padding: 20px 100px;
    }

    @media only screen and (max-width: 1300px) {
      padding: 20px 20px;
    }
    .actualities-content {
      padding-right: 0 !important;
    }
    .home-actualities-header {
      padding-right: 0px;
    }
    .promotion-item:last-child {
      padding-right: 10px;
    }
    .service-item-slider {
      cursor: pointer;
      text-align: center;
      font-size: 24px;
     
      color: $primary;
      letter-spacing: -1px;
      font-weight: bolder;
      .image-service-container {
        position: relative;
        height: 80px;
        margin-bottom: 20px;
      }
      .paragraph{
        text-align: justify;
        font-size: 12px;
        color: $descriptionGrey;
        //letter-spacing: -1px;
        font-weight: normal;
        margin-top: 15px;
      }
    }
    
    .promotion-item {
      position: relative;
      &:after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        background: $primary;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      &:hover:after {
        width: 35px;
        left: calc(50% - 30.5px + 1.5%);
      }
      &:last-child:hover:after {
        width: 35px;
        left: calc(50% - 17.5px);
      }
    }
  }
}

.home-service-slider-container {
  .home-actualities-container-mobile {
    .home-actualities-header {
      justify-content: center;
    }
    .home-actualities-title {
      svg.full-svg-service-plus {
        height: 35px;
      }
    }
    .promotion-item {
      margin-bottom: 10px;
      width: 45%;
      flex-basis: 50%;
      height: fit-content;
      .service-item-slider {
        font-size: 20px;
      }
    }
  }
  .slider-item-details {
    &.slider-item-details-mobile {
      justify-content: center;
      ////justify-content: flex-start;
    }
  }
}




.title-magazine {
  text-align: center;
  font-size: 14px;
  color: $primary;
  margin-top: 10px;
  font-family: $font_lato_bold;

}

.paragraph-magazine {
  text-align: justify;
  font-size: 12px;
  color: $descriptionGrey;
  margin-top: 12px;
  font-family: $font_lato_regular;

}
