@import 'main.scss';

.question-container-mobile {
  position: fixed;
  background: transparent;
  bottom: 20px;
  color: $primary;
  right: 20px;
  z-index: 9;
  .question-content-mobile {
    width: 50px;
    height: 50px;
    background: $white;
    border: 1px solid $primary;
    svg {
      margin: 0;
      width: 100%;
    }
    &:hover {
      background: $primary;
      svg .fill-blue {
        fill: $white;
      }
    }
  }
}
.question-container {
  position: fixed;
  background: transparent;
  bottom: 40px;
  color: $primary;
  right: 40px;
  z-index: 9;
  cursor: pointer;

  &:hover {
    color: $white;
    .talk-bubble {
      background: $primary;
    }
    .tri-right.border.btm-left-in:before {
      border-color: transparent transparent $primary;
    }
    .tri-right.btm-left-in:after {
      border-color: transparent transparent $primary;
    }
    .question-icon .fill-blue {
      fill: $white;
    }
  }
  .question-content {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    font-family: $font_lato_bold;
  }
  .question-icon {
    margin-right: 5px;
  }
  .talk-bubble {
    display: inline-block;
    position: relative;
    min-width: 185px;
    height: auto;
    background-color: $white;
  }
  .border {
    border: 1px solid $primary;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 11px 0px rgb(0 0 0 / 10%);
  }
  .round {
    border-radius: 30px;
  }
  .tri-right.border.btm-left-in:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: 39px;
    top: -22px;
    bottom: auto;
    border: 11px solid;
    border-color: transparent transparent $primary;
  }
  .tri-right.btm-left-in:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: 39px;
    top: -20px;
    bottom: -20px;
    border: 11px solid;
    border-color: transparent transparent $white;
  }
  .talktext {
    padding: 1em;
    text-align: left;
    line-height: 1.5em;
  }
}
