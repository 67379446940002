@import 'main.scss';

.contact-container {
  .contact-newsletter-container {
    margin-top: 60px;
  }
  .box-map-container {
    .marker {
      cursor: pointer;
      svg {
        width: 27px;
        height: 35px;
      }
      &.selected {
        z-index: 1;
        svg .fill-blue {
          fill: $yellow;
          stroke: $yellow;
        }
      }
    }
    .agency-search-container {
      position: absolute;
      top: 0;
      padding: 20px;
      z-index: 1;
      .countries-select-container {
        width: 352px;
        max-width: 100%;
        height: 50px;
        margin: 0 20px;
        .MuiFormControl-root {
          background: $primary;
          height: 100%;
          border-radius: 5px;
          .MuiInputBase-root {
            height: 100%;
            .MuiSelect-icon {
              color: $white;
              font-size: 25px;
              margin-right: 15px;
            }
            &.placeholder-select .MuiInput-input {
              opacity: 0.25;
            }
            &::before,
            &::after {
              border-bottom: unset;
            }
            .MuiSelect-root {
              height: 100%;
              padding-top: 0;
              padding-bottom: 0;
              display: flex;
              align-items: center;
              color: $white;
              font-size: 14px;
              font-family: $font_lato_regular;
              padding-left: 20px;
              padding-right: 40px;
            }
          }
        }
      }
      .agency-search-input-container {
        margin: 0 20px;
        width: 350px;
        .MuiAutocomplete-popper .MuiAutocomplete-paper {
          .MuiAutocomplete-listbox {
            max-height: 300px;
          }
          li span {
            font-size: 14px;
            color: $primary;
            font-family: $font_lato_regular;
          }
        }
        .agency-search-autocomplete {
          background: $white;
          max-width: 100%;
          border: 1px solid $primary;
          border-radius: 5px;
          height: 50px;
          .search-input {
            height: 100%;
            width: 100%;
            color: $primary;
            .MuiSvgIcon-root {
              color: $primary;
              font-size: 30px;
            }
            &::placeholder {
              color: $primary;
              opacity: 0.25;
              font-family: $font_lato_regular;
              font-size: 14px;
              text-transform: capitalize;
            }
            input {
              color: $primary;
              font-family: $font_lato_regular;
              font-size: 14px;
            }
            .MuiInputBase-root {
              height: 100%;
              padding: 0 20px;
              padding-right: 15px;
            }
          }
          .MuiInput-underline:before,
          .MuiInput-underline:after {
            border-bottom: unset;
          }
        }
      }
    }
    .map-agency-info {
      z-index: 1;
      position: absolute;
      left: 0;
      min-width: 300px;
      padding: 30px;
      bottom: calc(50% - 200px);
      max-width: 300px;
      height: 350px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .info-details-bloc-content {
        padding-left: 25px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 200px;
        margin: 0;
        a {
          display: flex;
          flex-wrap: nowrap;
          align-items: baseline;
        }
        &::-webkit-scrollbar-track {
          background: $backgroundWhiteLilac;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $primary;
          border-radius: 5px;
        }
        &::-webkit-scrollbar {
          width: 5px;
        }
        .info-details-bloc-content-item {
          padding-bottom: 10px;
          word-break: break-word;
          display: flex;
          flex-wrap: nowrap;
          align-items: baseline;
          .status-icon {
            svg {
              font-size: 15px;
            }
            &.open {
              color: $successColor;
            }
            &.closed {
              color: $errorColor;
            }
          }
          &.schedule-contrainer {
            padding-bottom: 0px;
            .openning-hours-container {
              flex-grow: 1;
              .agency-closed {
                color: $errorColor;
                font-size: 15px;
                padding-left: 5px;
              }
              .openning-hours-item {
                padding-bottom: 10px;
              }
              .hours-detail {
                padding-left: 5px;
                .from-to-txt {
                  padding-right: 5px;
                  padding-left: 5px;
                }
              }
              .day-txt {
                text-transform: capitalize;
                font-family: $font_lato_bold;
                display: flex;
                align-items: center;
                padding-bottom: 5px;
              }
            }
          }
          .MuiIconButton-root {
            padding: 0;
            width: 20px;
            height: 20px;
            margin: 0;
            .MuiSvgIcon-root {
              margin: 0;
            }
          }
        }
      }
      .agency-name-container {
        font-size: 20px;
        color: $primary;
        font-family: $font_lato_bold;
        text-transform: uppercase;

        .MuiDivider-root {
          height: 2px;
          background: $primary;
          width: 45px;
          margin-top: 15px;
          margin-bottom: 20px;
        }
        svg {
          height: 20px;
          margin-right: 10px;
          width: 15px;
          min-width: 15px;
        }
        .agency-type {
          font-family: $font_lato_regular;
          text-transform: capitalize;
          padding-bottom: 5px;
        }
      }
    }
    &.box-map-mobile {
      min-height: 775px;
      max-height: 775px;
      .map-agency-info {
        bottom: 5px;
        left: unset;
        padding: 20px 40px;
        max-width: calc(100% - 120px);
        width: calc(100% - 120px);
        height: 310px;
        min-width: unset;
        display: flex;
        justify-content: center;
        .agency-name-container {
          font-size: 25px;
          line-height: 30px;
          .MuiDivider-root {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }
      }
      .agency-search-container {
        .agency-search-input-container,
        .countries-select-container {
          width: 100%;
          margin: 5px 0;
        }
      }
    }
  }
}
.company-details-container {
  padding: 20px 130px;
  @media only screen and (max-width: 1500px) and (min-width: 1300px) {
    padding: 20px 80px;
  }

  @media only screen and (max-width: 1300px) {
    padding: 20px 20px;
  }

  .phone-container-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    p{
      white-space: nowrap;
    }
  }
  .phone-container-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p{
      white-space: nowrap;
    }
  }
  .info-details-bloc {
    text-align: center;
    padding: 20px;
    height: 100%;
    .info-details-bloc-title {
      color: $primary;
      font-family: $font_lato_bold;
      font-size: 21px;
      .MuiDivider-root {
        background-color: $primary;
        height: 2px;
        width: 40px;
        margin-top: 15px;
        margin-bottom: 20px;
        border-radius: 1px;
      }
    }

    .info-details-bloc-btn {
      color: $white;
      background: $primary;
      font-size: 14px;
      margin-top: auto;
      min-width: 200px;
      max-width: 100%;
      height: 40px;
      font-family: $font_lato_bold;
      border-radius: 5px;
      &:hover {
        background: $yellow;
        color: $primary;
      }
      &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5;
      }
    }
  }
}
.info-details-bloc-content {
  color: $descriptionGrey;
  font-family: $font_lato_regular;
  font-size: 16px;
  margin-bottom: 20px;
  a {
    color: $descriptionGrey;
    font-family: $font_lato_regular;
    font-size: 16px;
    text-decoration: none;
  }
  svg {
    margin-right: 7px;
    min-width: 10px;
  }
  .info-details-bloc-content-item {
    padding-bottom: 10px;
    &:last-child {
      padding-bottom: 0;
    }
    &.adress-info {
      cursor: pointer;
    }
  }
}

.countries-map-menu-dropdown {
  margin-top: 4px;
  border-radius: 5px;
  max-height: 300px;
  .MuiMenuItem-root {
    color: $primary;
    font-family: $font_lato_regular;
    font-size: 14px;
  }
}

.popup-agency {
  z-index: 1;
  max-width: 30%;
  .agency-name {
    font-size: 20px;
    color: $primary;
    font-family: $font_lato_bold;
    text-transform: uppercase;
    line-height: 30px;
    .MuiDivider-root {
      height: 2px;
      background: $primary;
      width: 45px;
      margin-top: 15px;
    }
    .agency-type {
      font-family: $font_lato_regular;
      text-transform: capitalize;
    }
  }
}
