@import 'main.scss';

.login-container {
  font-family: $font_lato_regular;
  min-height: 100vh;
  padding: 20px 10%;
  .MuiFormControl-root {
    margin: 20px 0;
    max-width: 600px;
  }
  button.login-btn {
    width: 100%;
    max-width: 600px;
    background: $primary;
    color: $white;
    margin-top: 20px;
    padding: 10px;
    font-family: $font_lato_bold;
    &:hover {
      background: $yellow;
    }
  }
  .loader-login {
    color: $white;
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
  }
}
