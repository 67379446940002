$white: #ffff;
$primary: #004899;
$drakBlue: #002147;
$lightWhite: #ffffff40;
$yellow: #feca3a;
$secondYellow: #d8a006;
$transparentWhite: #ffffffad;
$backdropColor: #0000004d;
$transparentShadow: #00000029;
$lightPrimary: #00489933;
$transparentPrimary: #e5ecf5;
$lighterPrimary: #1c4899;
$black: #000000;
$hoverColor: #f2f5fa;
$overlayImage: #00000073;
$descriptionGrey: #707070;
$grey: #6f6f6f;
$greyShadowDrop: #00000026;
$smallLink: #9b9b9b;
$green: #3e931d;
$lightBlue: #147bfb;
$orange: #fa8700;
$backgroundGrey: #1c489900;
$transparentBackgroundPrimary: #0048990d;
$backgroundWhiteLilac: #f2f5fa;
$transparentYellow: #feca3a8a;
$primaryWithOpacity: #00489966;
$rateStarsYellow: #ffc107;
$serviceColor: #255194;
$successColor: #008000;
$errorColor: #ff0000;
$hoverBtnBack: #0000000a;
$placeHolderColor: #004899bf;
$imagesPlaceHolderColor: #f9f9f9;
$bestPriceColor: #ffa800;
